import React from "react"
import moment from "moment"

export default function VenueList({ venues, selectedDate, createGame }) {
    return (
        <ul className="live-scoring-list">
            {venues
                .filter(
                    venue => venue.day === moment(selectedDate).format("dddd")
                )
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(venue => (
                    <li
                        className="live-scoring-venue-day"
                        key={venue.id}
                        onClick={() => createGame(venue.id)}
                    >
                        {venue.name}
                    </li>
                ))}
            {venues
                .filter(
                    venue => venue.day !== moment(selectedDate).format("dddd")
                )
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(venue => (
                    <li key={venue.id} onClick={() => createGame(venue.id)}>
                        {venue.name}
                    </li>
                ))}
        </ul>
    )
}
