import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import "./Game.css"
import Slide1 from "../components/Game/Slide1"
import Slide2 from "../components/Game/Slide2"
import ScoresWizardStep4 from "./Admin/Scores/Step4"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../firebase"
import dataService from "../data/dataService"

export default function Game() {
    const [teams, setTeams] = useState([])
    const [venues, setVenues] = useState([])
    const [games, setGames] = useState([])
    const [seasons, setSeasons] = useState([])
    const [loading, setLoading] = useState(true)
    const [game, setGame] = useState({})
    const [gameTeams, setGameTeams] = useState([])
    const [fullScreen, setFullScreen] = useState(false)
    let { gameid } = useParams()

    const fetchSeasons = async () => {
        await getDocs(collection(db, "seasons")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setSeasons(newData)
            setLoading(false)
        })
    }

    const fetchTeams = async () => {
        await getDocs(collection(db, "teams")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setTeams(newData)
        })
    }

    const fetchVenues = async () => {
        await getDocs(collection(db, "venues")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setVenues(newData)
        })
    }

    const fetchGames = async () => {
        await getDocs(collection(db, "games")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setGames(newData)
        })
    }

    useEffect(() => {
        fetchTeams()
        fetchVenues()
        fetchSeasons()
        fetchGames()
        dataService.getData("games", setLoading).then(data => {
            setGame(data.find(game => game.id === gameid))
        })
        dataService.getGameTeams(gameid).then(data => {
            setGameTeams(data)
        })
    }, [gameid])

    //  set an interval to check for changes to the game object and update the state if there are changes every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            dataService.getData("games", setLoading).then(data => {
                setGame(data.find(game => game.id === gameid))
            })
            dataService.getGameTeams(gameid).then(data => {
                setGameTeams(data)
            })
        }, 10000)
        return () => clearInterval(interval)
    }, [gameid])

    const setCurrentSlide = currentSlide => {
        const tmpGame = { ...game }
        tmpGame.currentSlide = currentSlide
        dataService.saveField(
            gameid,
            "currentSlide",
            currentSlide,
            "games",
            () => {}
        )
        setGame(tmpGame)
    }

    return (
        <div className={`game-page game-page-full-screen-${fullScreen}`}>
            <section className="game-area">
                {game.currentSlide === 1 && <Slide1 />}
                {game.currentSlide === 2 && <Slide2 />}
            </section>
            <section className="game-controls">
                <button
                    className="btn btn-primary full-screen"
                    onClick={() => setFullScreen(!fullScreen)}
                >
                    {fullScreen ? "Exit Full Screen" : "Full Screen"}
                </button>
                <ul className="game-controls-list">
                    <li>
                        <button
                            onClick={() =>
                                setCurrentSlide(game.currentSlide - 1)
                            }
                        >
                            &lt;
                        </button>
                    </li>
                    <li>
                        <span>Slide {game.currentSlide}</span>
                    </li>
                    <li>
                        <button
                            onClick={() =>
                                setCurrentSlide(game.currentSlide + 1)
                            }
                        >
                            &gt;
                        </button>
                    </li>
                </ul>
            </section>
            <section className="game-teams">
                <h2>Scores</h2>
                <ul className="game-teams-list">
                    {gameTeams.map(team => (
                        <li key={team.team}>
                            {teams.find(t => t.id === team.team)?.name} - 0
                        </li>
                    ))}
                </ul>
            </section>
        </div>
    )
}

// this is the frontend for a user to join a game

/*

host goes to the add a score page and there is an option to host a live game
host clicks on the button to host a live game
which askes you questions, what season, what venue, today's date, what time (auto-populates based on the venue and date)

then it takes you to the game page
from here there is a presenter view, host view, and player view

presenter view is what would be projected on to a screen in the bar. 
It shows the teams, the scores, the time, and the questions
using the powerpoint as a guide, the presenter can click through the questions and the answers will be displayed on the screen.
There will also be a QR code that can be scanned by the players to see the playerview on their phones

first it will show the teams that are listed for tonight, and then animate back and forth between that and the current local scores (hilighting teams that are there signed-up for tonight)

host view is what the host sees on their phone. This will allow them to enter the teams currently playing by using a dropdown (can also use a temp name for the night) or manually adding a new team if they don't find them. Host view also has buttons for progressing to the next question/slide

At the end of each round, hostview will ask for scores from each team. Click on a team, see buttons for each question and clicking it once marks it correct and shows the correct answer in green. a second time marks it incorrect and shows the correct answer in red. A third time brings it back to not answered. Score for that round cannot be submitted until all buttons are red or green. Once all scores are entered for the round, the results of the round are shown as well as the running scoreboard for the game. Hosts can then click next round, prompting a category choice except for rounds 3 and 6.

player view is what the players see on their phones. This will allow them to see live scoreboard.

*/
