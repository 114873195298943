import React from "react"
import styled from "styled-components"

import "./Medal.css"
import "./MedalMobile.css"

import {
    PuzzlePieceIcon,
    BookOpenIcon,
    GlobeAmericasIcon,
    QuestionMarkCircleIcon,
    MusicalNoteIcon,
    FlagIcon,
    PhotoIcon,
    BeakerIcon,
    FilmIcon,
    StarIcon,
} from "@heroicons/react/24/solid"
import {
    GiWorld,
    GiPerspectiveDiceSixFacesRandom,
    GiAncientColumns,
} from "react-icons/gi"
import { MdScoreboard } from "react-icons/md"
import { PiShootingStar, PiPopcorn } from "react-icons/pi"
import { IoIosBaseball } from "react-icons/io"
import { GiMagnifyingGlass } from "react-icons/gi"

export function Medal({ name, value }) {
    const rankings = [
        {
            name: "Games Played",
            label: "Games Played",
            icon: <GiPerspectiveDiceSixFacesRandom className="medal-icon" />,
            ratings: [5, 15, 60, 150, 220, 500],
            // bronze, silver, gold, platinum, diamond, master
        },
        {
            name: "Games Won",
            label: "Games Won",
            ratings: [1, 5, 25, 50, 100],
        },
        {
            name: "History",
            label: "History",
            icon: <GiAncientColumns className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Science",
            label: "Science",
            icon: <BeakerIcon className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Sports",
            label: "Sports",
            icon: <IoIosBaseball className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Places",
            label: "Places",
            icon: <GiWorld className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Pictures",
            label: "Pictures",
            icon: <PhotoIcon className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Mystery",
            label: "Mystery",
            icon: <GiMagnifyingGlass className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Music",
            label: "Music",
            icon: <MusicalNoteIcon className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Movies",
            label: "Movies",
            icon: <PiPopcorn className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Pop Culture",
            label: "Pop Culture",
            icon: <PiShootingStar className="medal-icon" />,
            ratings: [10, 50, 200, 500, 1000, 2000],
        },
        {
            name: "Total Score",
            label: "Total Score",
            icon: <MdScoreboard className="medal-icon" />,
            ratings: [50, 300, 1200, 4000, 10000, 20000],
        },
    ]

    const calculateMedalClass = (medalName, metric) => {
        const medal = rankings.find(r => r.name === medalName)
        const rating = metric
        let className = `${medalName.replace(" ", "").toLowerCase()} `
        
        if (rating >= medal.ratings[5]) {
            className += "master"
        } else if (rating >= medal.ratings[4]) {
            className += "diamond"
        } else if (rating >= medal.ratings[3]) {
            className += "platinum"
        } else if (rating >= medal.ratings[2]) {
            className += "gold"
        } else if (rating >= medal.ratings[1]) {
            className += "silver"
        } else if (rating >= medal.ratings[0]) {
            className += "bronze"
        } else {
            className += "none"
        }
        return className
    }

    const calculateMedalProgress = (medalName, metric) => {
        const medal = rankings.find(r => r.name === medalName)
        const rating = metric

        if (rating >= medal.ratings[5]) {
            return 100
        } else if (rating >= medal.ratings[4]) {
            return (rating / medal.ratings[5]) * 100
        } else if (rating >= medal.ratings[3]) {
            return (rating / medal.ratings[4]) * 100
        } else if (rating >= medal.ratings[2]) {
            return (rating / medal.ratings[3]) * 100
        } else if (rating >= medal.ratings[1]) {
            return (rating / medal.ratings[2]) * 100
        } else if (rating >= medal.ratings[0]) {
            return (rating / medal.ratings[1]) * 100
        } else {
            return (rating / medal.ratings[0]) * 100
        }
    }

    const calculateNextValue = (medalName, metric) => {
        const medal = rankings.find(r => r.name === medalName)
        const rating = metric

        if (rating >= medal.ratings[5]) {
            return "MAX"
        } else if (rating >= medal.ratings[4]) {
            return `${rating} / ${medal.ratings[5]}`
        } else if (rating >= medal.ratings[3]) {
            return `${rating} / ${medal.ratings[4]}`
        } else if (rating >= medal.ratings[2]) {
            return `${rating} / ${medal.ratings[3]}`
        } else if (rating >= medal.ratings[1]) {
            return `${rating} / ${medal.ratings[2]}`
        } else if (rating >= medal.ratings[0]) {
            return `${rating} / ${medal.ratings[1]}`
        } else {
            return `${rating} / ${medal.ratings[0]}`
        }
    }

    return (
        <li key={name} className={`medal ${calculateMedalClass(name, value)}`}>
            <span className="medal-icon">
                {rankings.find(r => r.name === name)?.icon}
            </span>
            <span className="medal-row">
                <span className="medal-top-row">
                    <span className="medal-label">
                        {rankings.find(r => r.name === name)?.label}
                    </span>
                    <span className="medal-value">{value}</span>
                </span>
                <span className="medal-bottom-row">
                    <span className="medal-progress">
                        <ProgressBar
                            className={`medal-progress-bar ${name.toLowerCase()}`}
                            progress={calculateMedalProgress(name, value)}
                        />
                    </span>
                </span>
                <span className="medal-footer-row">
                    <span className="medal-rating">
                        {calculateMedalClass(name, value) !== "none"
                            ? name
                            : ""}
                    </span>
                    <span className="medal-remaining">
                        {calculateNextValue(name, value)}
                    </span>
                </span>
            </span>
        </li>
    )
}

const ProgressBar = styled.div`
    height: 10px;
    width: ${props => props.progress}%;
    background-color: lightgreen;
    transition: width 0.5s;
`
