import React, { useEffect, useState } from "react"
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from "chart.js"
import { Radar } from "react-chartjs-2"

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
)

export function RadarChart({ teamData }) {
    const [data, setData] = useState({
        labels: [
            "History",
            "Science",
            "Music",
            "Movies",
            "Places",
            "Sports",
            "Mystery",
            "Pop Culture",
        ],
        datasets: [
            {
                label: "% Correct",
                data: teamData ? teamData : [25, 90, 30, 50, 20, 30, 40, 50],
                backgroundColor: "rgba(99,212,91, 0.2)",
                borderColor: "rgba(46,237,32,1)",
                borderWidth: 1,
            },
        ],
    })

    useEffect(() => {
        const teamDataFormatted = teamData.map(data => Number(data).toFixed(2))
        setData({
            labels: [
                "History",
                "Science",
                "Music",
                "Movies",
                "Places",
                "Sports",
                "Mystery",
                "Pop Culture",
            ],
            datasets: [
                {
                    label: "% Correct",
                    data: teamDataFormatted
                        ? teamDataFormatted
                        : [25, 90, 30, 50, 20, 30, 40, 50],
                    backgroundColor: "rgba(99,212,91, 0.2)",
                    borderColor: "rgba(46,237,32,1)",
                    borderWidth: 1,
                    pointBackgroundColor: [
                        "#06aff0",
                        "#92cf51",
                        "#ffbf00",
                        "#ff0000",
                        "#ffff00",
                        "#70309f",
                        "white",
                        "#fe7ce9",
                    ],
                    pointBorderColor: [
                        "#06aff0",
                        "#92cf51",
                        "#ffbf00",
                        "#ff0000",
                        "#ffff00",
                        "#70309f",
                        "black",
                        "#fe7ce9",
                    ],
                },
            ],
        })
    }, [teamData])

    return (
        <Radar
            data={{
                ...data,
            }}
            options={{
                elements: {
                    line: {
                        borderWidth: 10,
                    },
                    point: {
                        pointRadius: 5,
                        pointHitRadius: 10,
                    },
                },
                scales: {
                    r: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        ticks: {
                            stepSize: 20,
                        },
                    },
                },
                datasets: {
                    backgroundColor: "red",
                },
            }}
        />
    )
}

// ChartJS.defaults.plugins.tooltip.pointStyle = "crossRot"
// ChartJS.defaults.plugins.tooltip.pointRadius = 5
