import "./App.css"
import "./Mobile.css"

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import Login from "./pages/Authentication/Login"
import Register from "./pages/Authentication/Register"
import Reset from "./pages/Authentication/Reset"
import Dashboard from "./pages/Dashboard"
import Layout from "./components/Layout"
import Schedule from "./pages/Schedule"
import About from "./pages/About"
import Standings from "./pages/Standings"
import Admin from "./pages/Admin/Admin"
import UserAdmin from "./pages/Admin/Users"
import HostDashboard from "./pages/Admin/HostDashboard"
import SeasonsAdmin from "./pages/Admin/Seasons"
import VenuesAdmin from "./pages/Admin/Venues"
import TeamsAdmin from "./pages/Admin/Teams"
import TeamPage from "./pages/Team"
import CategoryAdmin from "./pages/Admin/Categories"
import QuestionAdmin from "./pages/Admin/Questions"
import Venue from "./pages/Venue"
import PagesAdmin from "./pages/Admin/Pages"
import HostGame from "./pages/HostGame"
import GameTemplateAdmin from "./pages/Admin/GameTemplates"
import Game from "./pages/Game"
import GamesAdmin from "./pages/Admin/GamesAdmin"
import GameDetailsAdmin from "./pages/Admin/GameDetailsAdmin"
import { useEffect } from "react"
import useCategoryStore from "./data/categoryStore"
import useBadgeStore from "./data/badgeStore"
import useTeamStore from "./data/teamStore"

function App() {
    useEffect(() => {
        // TODO: create a loading store
        useCategoryStore.getState().getCategories()
        useBadgeStore.getState().getBadges()
        useTeamStore.getState().getTeams()
    }, [])

    return (
        <>
            <Router>
                <Layout>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/register" element={<Register />} />
                        <Route exact path="/reset" element={<Reset />} />
                        <Route
                            exact
                            path="/dashboard"
                            element={<Dashboard />}
                        />
                        <Route exact path="/schedule" element={<Schedule />} />
                        <Route exact path="/about" element={<About />} />

                        <Route exact path="/host-game" element={<HostGame />} />

                        <Route
                            exact
                            path="/standings/:slug"
                            element={<Standings />}
                        />

                        <Route exact path="/venues/:id" element={<Venue />} />

                        <Route exact path="/team/:id" element={<TeamPage />} />

                        <Route exact path="/admin" element={<Admin />} />
                        <Route
                            exact
                            path="/host-dashboard"
                            element={<HostDashboard admin={false} />}
                        />
                        <Route
                            exact
                            path="/admin/users"
                            element={<UserAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/scores"
                            element={<HostDashboard admin={true} />}
                        />
                        <Route
                            exact
                            path="/admin/games"
                            element={<GamesAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/games/:gameid"
                            element={<GameDetailsAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/seasons"
                            element={<SeasonsAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/venues"
                            element={<VenuesAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/teams"
                            element={<TeamsAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/categories"
                            element={<CategoryAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/questions"
                            element={<QuestionAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/game-templates"
                            element={<GameTemplateAdmin />}
                        />
                        <Route
                            exact
                            path="/admin/pages"
                            element={<PagesAdmin />}
                        />
                        <Route
                            exact
                            path="/add-score"
                            element={<HostDashboard admin={false} />}
                        />
                        <Route exact path="/game/:gameid" element={<Game />} />
                        <Route path="*" element={<h1>Not Found</h1>} />
                    </Routes>
                </Layout>
            </Router>
        </>
    )
}
export default App
