import React, { useEffect, useState } from "react";

export default function PageForm({ onSubmit, page, onCancel }) {
  const [color, setColor] = useState("red");
  const [title, setTitle] = useState("");
  const [subheadline, setSubheadline] = useState("");
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (page) {
      setColor(page.color);
      setTitle(page.title);
      setSubheadline(page.subheadline);
      setSlug(page.slug);
      setContent(page.content);
    }
  }, [page]);

  const handleCancel = () => {
    onCancel();
    setTitle("");
    setSubheadline("");
    setSlug("");
    setContent("");
    setColor("red");
  };

  return (
    <form className="block w-100" onSubmit={onSubmit}>
      <p>
        <label htmlFor="title">Title</label>
        <input type="text" name="title" value={title} />
      </p>
      <p>
        <label htmlFor="subheadline">Subheadline</label>
        <input type="text" name="subheadline" value={subheadline} />
      </p>
      <p>
        <label htmlFor="slug">Slug</label>
        <input type="text" name="slug" value={slug} />
      </p>
      <p>
        <label htmlFor="color">Color</label>
        <div className="flex jc-space-between ai-center">
          <select
            name="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          >
            <option value="red">Red</option>
            <option value="blue">Blue</option>
            <option value="green">Green</option>
            <option value="yellow">Yellow</option>
            <option value="orange">Orange</option>
            <option value="purple">Purple</option>
            <option value="pink">Pink</option>
            <option value="gray">Gray</option>
          </select>
          <div className={`color-preview bg-${color}`}></div>
        </div>
      </p>
      <p>
        <label htmlFor="content">Content</label>
        <textarea name="content" value={content}></textarea>
      </p>
      <p>
        <button type="submit">{title !== "" ? "Save Page" : "Add Page"}</button>
        {title !== "" && (
          <button type="button" onClick={handleCancel}>
            Cancel
          </button>
        )}
      </p>
    </form>
  );
}
