import React, { useEffect, useState } from "react"
// import { Link } from "react-router-dom"

import { db } from "../firebase"
import { collection, getDocs } from "firebase/firestore"

import ScoresWizardStep1 from "./Admin/Scores/Step1"
import ScoresWizardStep2 from "./Admin/Scores/Step2"
import ScoresWizardStep3 from "./Admin/Scores/Step3"
import ScoresWizardStep5 from "./Admin/Scores/Step5"
import moment from "moment"

import "./HostGame.css"
import ScoresWizardStep4 from "./Admin/Scores/Step4"
import { Link } from "react-router-dom"

export default function HostGame() {
    const [wizardStep, setWizardStep] = useState(0)
    const [seasons, setSeasons] = useState([])
    const [venues, setVenues] = useState([])
    const [teams, setTeams] = useState([])
    const [scores, setScores] = useState([])
    const [games, setGames] = useState([])
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [selectedSeason, setSelectedSeason] = useState("")
    const [selectedDate, setSelectedDate] = useState("")
    const [selectedVenue, setSelectedVenue] = useState("")
    const [addTeams, setAddTeams] = useState(false)

    const fetchSeasons = async () => {
        await getDocs(collection(db, "seasons")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setSeasons(newData)
            setLoading(false)
        })
    }

    const fetchTeams = async () => {
        await getDocs(collection(db, "teams")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setTeams(newData)
        })
    }

    const fetchVenues = async () => {
        await getDocs(collection(db, "venues")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setVenues(newData)
        })
    }

    const fetchGames = async () => {
        await getDocs(collection(db, "games")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setGames(newData)
        })
    }

    useEffect(() => {
        fetchSeasons()
        fetchTeams()
        fetchVenues()
        fetchGames()
    }, [])

    return (
        <section className="scores-enter-page">
            <h1>Host a Game</h1>
            <hr />
            <section>
                <button
                    className="btn btn-success create-game"
                    onClick={() => setWizardStep(1)}
                >
                    Create a New Game
                </button>
            </section>
            <hr />
            <section className="your-games">
                <h2>Your Games</h2>
                <ul className="game-list">
                    {games.map(game => (
                        <li key={game.id}>
                            {moment(game.date).format("dddd MM/DD/YYYY")} -{" "}
                            {
                                venues.find(venue => venue.id === game.venue)
                                    ?.name
                            }{" "}
                            - {game.status}
                            <button
                                onClick={() => {
                                    setAddTeams(game)
                                    setWizardStep(5)
                                }}
                            >
                                Add Teams
                            </button>
                            <Link to={`/game/${game.id}`}>
                                <button>View Game</button>
                            </Link>
                        </li>
                    ))}
                </ul>
            </section>
            {wizardStep > 0 && (
                <section className="wizard-overlay">
                    <section className="wizard">
                        {wizardStep === 1 && (
                            <ScoresWizardStep1
                                type="game"
                                seasons={seasons}
                                venues={venues}
                                teams={teams}
                                setUpdating={setUpdating}
                                setScores={setScores}
                                scores={scores}
                                fetchTeams={fetchTeams}
                                setWizardStep={setWizardStep}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                        {wizardStep === 2 && (
                            <ScoresWizardStep2
                                type="game"
                                setWizardStep={setWizardStep}
                                setSelectedDate={setSelectedDate}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                        {wizardStep === 3 && (
                            <ScoresWizardStep3
                                type="game"
                                venues={venues}
                                setWizardStep={setWizardStep}
                                setSelectedVenue={setSelectedVenue}
                                selectedDate={selectedDate}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                            />
                        )}

                        {wizardStep === 4 && (
                            <ScoresWizardStep5
                                type="game"
                                setWizardStep={setWizardStep}
                                venue={venues.find(
                                    venue => venue.id === selectedVenue
                                )}
                                date={selectedDate}
                                season={seasons.find(
                                    season => season.id === selectedSeason
                                )}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                                fetchData={fetchGames}
                            />
                        )}

                        {addTeams && (
                            <ScoresWizardStep4
                                type="game"
                                teams={teams}
                                setWizardStep={setWizardStep}
                                venue={venues.find(
                                    venue => venue.id === addTeams.venue
                                )}
                                date={addTeams.date}
                                season={seasons.find(
                                    season => season.id === addTeams.season
                                )}
                                fetchTeams={fetchTeams}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                                game={addTeams}
                                setAddTeams={setAddTeams}
                            />
                        )}
                    </section>
                </section>
            )}
        </section>
    )
}
