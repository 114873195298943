import React, { useState } from "react"
import moment from "moment"
import { Timestamp, addDoc, collection } from "firebase/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth, db } from "../../../firebase"
export default function ScoresWizardStep5({
    setWizardStep,
    date,
    venue,
    season,
    setSelectedSeason,
    setSelectedVenue,
    setSelectedDate,
    fetchData,
    type = "scores",
}) {
    const [user] = useAuthState(auth)
    const [saving, setSaving] = useState(false)
    const complete = () => {
        if (type === "game") {
            setSaving(true)
            const data = {
                season: season.id,
                venue: venue.id,
                date: date,
                user: user.uid,
                status: "created",
                currentSlide: 1,
            }
            addDoc(collection(db, "games"), data)

            setTimeout(() => {
                fetchData()
                setWizardStep(0)
                setSelectedSeason("")
                setSelectedVenue("")
                setSelectedDate("")
                setSaving(false)
            }, 2500)
        } else {
            fetchData()
            setWizardStep(0)
            setSelectedSeason("")
            setSelectedVenue("")
            setSelectedDate("")
        }
    }
    return (
        <section>
            <span
                className="close"
                onClick={() => {
                    fetchData()
                    setWizardStep(0)
                    setSelectedSeason("")
                    setSelectedDate("")
                    setSelectedVenue("")
                }}
            >
                x
            </span>
            {saving ? (
                <h1>Creating Game...</h1>
            ) : (
                <>
                    <h1>{type === "game" ? "Create Game" : "Enter Scores"}</h1>
                    <h2>Confirmation</h2>
                    {type === "game" ? (
                        <p>Confirm to create a game for:</p>
                    ) : (
                        <p>Thanks for submitting your scores for:</p>
                    )}
                    <p>
                        <strong>
                            {moment(date).format("dddd MMM Do YYYY")}
                        </strong>
                    </p>
                    <p>
                        <strong>{venue.name}</strong>
                    </p>
                    <p>
                        <strong>{season.name}</strong>
                    </p>
                    <p>
                        <button className="btn btn-success" onClick={complete}>
                            Finish
                        </button>
                    </p>
                </>
            )}
        </section>
    )
}
