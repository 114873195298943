import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"

export default function CategoryAdmin() {
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState("")
    const [updating, setUpdating] = useState(false)
    const fetchPost = async () => {
        await getDocs(collection(db, "categories")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }))
            setCategories(newData)
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchPost()
    }, [])

    const updateName = async (id, name) => {
        setUpdating(true)
        const catRef = doc(db, "categories", id)
        await updateDoc(catRef, {
            name: name,
        })
        setCategories(
            categories.map(category => {
                if (category.id === id) {
                    return {
                        ...category,
                        name,
                    }
                }
                return category
            })
        )
        setUpdating(false)
    }

    const addCategory = async e => {
         e.preventDefault()
         setUpdating(true)
         const catRef = await addDoc(collection(db, "categories"), {
               name: e.target.name.value,
         })
         setCategories([
               ...categories,
               {
                  id: catRef.id,
                  name: e.target.name.value,
               },
         ])
         setUpdating(false)
         e.target.name.value = ""
      }

    const search = e => {
        setQuery(e.target.value)
    }

    return (
        <section className="categories-admin-page">
            <h1>Categories</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            <p>{loading && <span>Categories: Loading...</span>}</p>
            <p>{updating && <span>Categories: Updating...</span>}</p>
            <section>
                <input
                    type="text"
                    onChange={search}
                    placeholder="Search Categories..."
                />
            </section>
            <details>
                <summary>Add Category</summary>
                <form onSubmit={addCategory}>
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" />
                    <button type="submit">Add</button>
                </form>
            </details>
            {categories && (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories
                            .filter(category =>
                                query !== ""
                                    ? category.name
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                    : true
                            )
                            .map(category => (
                                <tr key={category.id}>
                                    <td>
                                          <input
                                             type="text"
                                             value={category.name}
                                             onChange={e =>
                                                   updateName(
                                                      category.id,
                                                      e.target.value
                                                   )
                                             }
                                          />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </section>
    )
}
