import create from "zustand"
import dataService from "./dataService"

const useCategoryStore = create(set => ({
    categories: [],
    setCategories: categories => set({ categories }),
    getCategories: () => {
        dataService
            .getData("categories", () => {})
            .then(data => {
                set({ categories: data })
            })
    },
}))

export default useCategoryStore