import React from "react"

import "./ScoreboardList.css"

export default function ScoreboadList({ gameScoreboard, prepareScoreboard }) {
    React.useEffect(() => {
        const interval = setInterval(() => {
            prepareScoreboard()
        }, 5000)
        return () => clearInterval(interval)
    }, [prepareScoreboard])

    return (
        <ul className="live-scoring-list scoreboard">
            <h2>Scoreboard</h2>
            {gameScoreboard
                .sort((a, b) => b.score - a.score)
                .map((team, index) => (
                    <li key={team.team}>
                        <span>{index + 1}</span>
                        <span className="scoreboard-name">
                            {team.displayName !== ""
                                ? team.displayName
                                : team.team}{" "}
                            <strong>[{team.team}]</strong>
                        </span>
                        <span className="round-item-score">{team.score}</span>
                    </li>
                ))}
        </ul>
    )
}
