import { Link } from "react-router-dom"
import { useState, useEffect, useCallback } from "react"
import {
    GoogleMap,
    useJsApiLoader,
    MarkerF,
    InfoBoxF,
} from "@react-google-maps/api"

import { collection, doc, addDoc, getDocs, updateDoc } from "firebase/firestore"
import { db } from "../firebase"

import dataService from "../data/dataService"

import "./Schedule.css"
import Loading from "../components/Loading"

const containerStyle = {
    width: "400px",
    height: "500px",
}

const center = {
    lat: 38.553921,
    lng: -76.805295,
}

export default function Schedule() {
    const [venues, setVenues] = useState([])
    const [loading, setLoading] = useState(false)
    const [map, setMap] = useState(null)
    const [activeMarker, setActiveMarker] = useState(null)

    const [page, setPage] = useState(null)

    useEffect(() => {
        dataService
            .getData("pages", () => {})
            .then(data => {
                setPage(data.find(page => page.slug === "schedule"))
            })
    }, [])

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyCA6l3shVH6uWWuG2-q_7G0A5eqTSjI1F8",
    })

    const onLoad = useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center)
        map.setZoom(9)
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const fetchVenues = async () => {
        await getDocs(collection(db, "venues")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setVenues(newData)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchVenues()
        setLoading(false)
    }, [])

    return page ? (
        <section className={`schedule-page bg-${page?.color}`}>
            <h1>{page?.title}</h1>
            <h2>{page?.subheadline}</h2>
            <p>{page?.content} </p>
            <div className="schedule-page-wrapper">
                <div className="schedule-page-left">
                    <section>
                        <h2>Tuesdays:</h2>

                        <ul>
                            {venues
                                .filter(venue => venue.day === "Tuesday")
                                .map(venue => (
                                    <li key={venue.id}>
                                        <Link to={`/venues/${venue.id}`}>
                                            {venue.name} - {venue.time}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </section>
                    <section>
                        <h2>Wednesdays:</h2>

                        <ul>
                            {venues
                                .filter(venue => venue.day === "Wednesday")
                                .map(venue => (
                                    <li key={venue.id}>
                                        <Link to={`/venues/${venue.id}`}>
                                            {venue.name} - {venue.time}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </section>
                    <section>
                        <h2>Thursdays:</h2>

                        <ul>
                            {venues
                                .filter(venue => venue.day === "Thursday")
                                .sort((a, b) => (b.time > a.time ? 1 : -1))
                                .map(venue => (
                                    <li key={venue.id}>
                                        <Link to={`/venues/${venue.id}`}>
                                            {venue.name} - {venue.time}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </section>
                </div>
                <div className="schedule-page-right">
                    {isLoaded && venues.length > 0 ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            zoom={9}
                        >
                            {/* Child components, such as markers, info windows, etc. */}
                            {venues.map(venue => (
                                <MarkerF
                                    key={venue.id}
                                    position={{
                                        lat: Number(venue.lat),
                                        lng: Number(venue.lng),
                                    }}
                                    title={venue.name}
                                    onClick={() => {
                                        // popup the info window
                                        setActiveMarker(venue.id)
                                        map.setCenter({
                                            lat: Number(venue.lat),
                                            lng: Number(venue.lng),
                                        })
                                    }}
                                >
                                    {activeMarker &&
                                        activeMarker === venue.id && (
                                            <InfoBoxF
                                                options={{
                                                    closeBoxURL: ``,
                                                    enableEventPropagation: true,
                                                    pane: "overlayLayer",
                                                }}
                                            >
                                                <p className="venue-infobox">
                                                    {venue.name}
                                                </p>
                                            </InfoBoxF>
                                        )}
                                </MarkerF>
                            ))}
                        </GoogleMap>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </section>
    ) : (
        <Loading />
    )
}
