import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import {
    collection,
    doc,
    getDoc,
    addDoc,
    getDocs,
    updateDoc,
} from "firebase/firestore"
import { db } from "../../firebase"

export default function TeamsAdmin() {
    const [teams, setTeams] = useState([])
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [users, setUsers] = useState([])
    const [query, setQuery] = useState("")
    const fetchTeams = async () => {
        await getDocs(collection(db, "teams")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setTeams(newData)
        })
    }

    const fetchUsers = async () => {
        await getDocs(collection(db, "users")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }))
            setUsers(newData)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchTeams()
        fetchUsers()
        setLoading(false)
    }, [])

    const updateTeamName = async (id, name) => {
        setTeams(
            teams.map(team => {
                if (team.id === id) {
                    return {
                        ...team,
                        name,
                    }
                }
                return team
            })
        )
    }

    const saveTeamName = async (id, name) => {
        setUpdating(true)
        const teamRef = doc(db, "teams", id)
        await updateDoc(teamRef, {
            name,
        })
        setUpdating(false)
    }

    const updateTeamCaptain = async (id, captain) => {
        setUpdating(true)
        const teamRef = doc(db, "teams", id)
        await updateDoc(teamRef, {
            captain,
        })

        setTeams(
            teams.map(team => {
                if (team.id === id) {
                    return {
                        ...team,
                        captain,
                    }
                }
                return team
            })
        )
        setUpdating(false)
    }

    const addTeam = async e => {
        e.preventDefault()
        setUpdating(true)
        const name = e.target.name.value
        const captain = e.target.captain.value

        const teamRef = await addDoc(collection(db, "teams"), {
            name,
            captain,
        })

        setTeams([
            ...teams,
            {
                id: teamRef.id,
                name,
                captain,
            },
        ])
        setUpdating(false)
        e.target.reset()
    }

    const search = e => {
        const keyword = e.target.value
        setQuery(keyword)
    }

    return (
        <section className="teams-admin-page">
            <h1>Teams</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            <p>{loading && <span>Teams: Loading...</span>}</p>
            <p>{updating && <span>Teams: Updating...</span>}</p>
            <section>
                <input
                    type="text"
                    placeholder="Search Teams...."
                    onChange={search}
                />
            </section>
            <details>
                <summary>Add Team</summary>
                <form onSubmit={addTeam}>
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" />
                    <label htmlFor="captain">Captain</label>
                    <select id="captain">
                        <option value="">None</option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>
                                {`${user.email} - ${user.name}`}
                            </option>
                        ))}
                    </select>
                    <button type="submit">Add Team</button>
                </form>
            </details>
            {teams && (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Captain</th>
                            <th>Team Page</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teams
                            .filter(team =>
                                query !== ""
                                    ? team.name
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                    : true
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(team => (
                                <tr key={team.id}>
                                    <td>
                                        <input
                                            type="text"
                                            value={team.name}
                                            onChange={e =>
                                                updateTeamName(
                                                    team.id,
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveTeamName(
                                                    team.id,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <select
                                            value={team.captain}
                                            onChange={e =>
                                                updateTeamCaptain(
                                                    team.id,
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">None</option>
                                            {users.map(user => (
                                                <option
                                                    key={user.id}
                                                    value={user.id}
                                                >
                                                    {`${user.email} - ${user.name}`}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <Link to={`/team/${team.id}`}>
                                            {team.name} Page
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </section>
    )
}
