import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"

export default function GameTemplateAdmin() {
    const [categories, setCategories] = useState([])
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState("")
    const [updating, setUpdating] = useState(false)

    const fetchPost = async () => {
        await getDocs(collection(db, "categories")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }))
            setCategories(newData)
            setLoading(false)
        })
        await getDocs(collection(db, "questions")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }))
            setQuestions(newData)
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchPost()
    }, [])

    const updateField = async (id, field, value) => {
        setQuestions(
            questions.map(question => {
                if (question.id === id) {
                    return {
                        ...question,
                        [field]: value,
                    }
                }
                return question
            })
        )
    }

    const saveField = async (id, field, value) => {
        setUpdating(true)
        const docRef = doc(db, "questions", id)
        await updateDoc(docRef, {
            [field]: value,
        })
        setQuestions(
            questions.map(question => {
                if (question.id === id) {
                    return {
                        ...question,
                        [field]: value,
                    }
                }
                return question
            })
        )
        setUpdating(false)
    }

    const addQuestion = async e => {
        e.preventDefault()
        setUpdating(true)
        if (
            e.target.title.value === "" ||
            e.target.answer.value === "" ||
            e.target.category.value === ""
        ) {
            alert("Please fill out all fields")
            return
        }
        const docRef = await addDoc(collection(db, "questions"), {
            title: e.target.title.value,
            answer: e.target.answer.value,
            category: e.target.category.value,
        })
        setQuestions([
            ...questions,
            {
                id: docRef.id,
                title: e.target.title.value,
                answer: e.target.answer.value,
                category: e.target.category.value,
            },
        ])
        setUpdating(false)
        e.target.reset()
    }

    const search = e => {
        setQuery(e.target.value)
    }

    return (
        <section className="gametemplates-admin-page">
            <h1>Game Templates</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            <p>{loading && <span>Questions: Loading...</span>}</p>
            <p>{updating && <span>Questions: Updating...</span>}</p>
            <section>
                {/* put the date here */}
            </section>
            
        </section>
    )
}
