import React from "react"
import Loading from "../Loading"

export default function TeamScoringView({
    currentRound,
    currentTeam,
    gameScores,
    gameRounds,
    setScoring,
    teams,
    gameTeams,
    numberOfRoundsPerGame,
    questionsPerRound,
    setScoreTouched,
}) {
    const numberOfQuestions = questionsPerRound[currentRound - 1]

    const [teamScore, setTeamScore] = React.useState({})
    const [saving, setSaving] = React.useState(false)

    React.useEffect(() => {
        const tscore = gameScores.find(
            gs =>
                gs.team === currentTeam &&
                gs.round ===
                    gameRounds.find(round => round.round === currentRound).id
        ) || {
            team: currentTeam,
            round: gameRounds.find(round => round.round === currentRound).id,
            questions: Array.from({ length: numberOfQuestions }, (_, i) => ({
                question: i + 1,
                correct: false,
            })),
            local: true,
        }
        setTeamScore(tscore)
    }, [currentRound, currentTeam, gameScores, gameRounds])

    React.useEffect(() => {
        if (
            teamScore &&
            teamScore.questions &&
            teamScore.questions.length > 0
        ) {
            if (
                teamScore.touched === undefined ||
                teamScore.touched === false
            ) {
                setScoreTouched(teamScore)
            }
        }
    }, [teamScore])

    const updateScoring = (question, correct) => {
        const updatedQuestions = teamScore.questions.map(q =>
            q.question === question ? { ...q, correct } : q
        )
        setTeamScore({ ...teamScore, questions: updatedQuestions })
        if (teamScore.local) {
            setSaving(true)
            setTimeout(() => {
                setSaving(false)
            }, 1000)
        }
        setScoring(currentTeam, currentRound, question, correct)
    }

    return (
        <div className="team-scoring-view">
            <h2 className="team-scoring-team-name">
                <span className="display-name">
                    {gameTeams.find(gt => gt.team === currentTeam)
                        ?.displayName ||
                        teams.find(t => t.id === currentTeam)?.name}
                </span>
                <span className="team-name">
                    [{teams.find(t => t.id === currentTeam)?.name}]
                </span>
            </h2>
            {!saving &&
            teamScore &&
            teamScore.questions &&
            teamScore.questions.length > 0 ? (
                <ul className="team-scoring-list">
                    {Array.from(
                        { length: numberOfQuestions },
                        (_, i) => i + 1
                    ).map(question => (
                        <li key={question}>
                            {teamScore.questions.find(
                                q => q.question === question
                            )?.correct ? (
                                <button
                                    className="correct"
                                    onClick={() =>
                                        updateScoring(question, false)
                                    }
                                >
                                    {question}
                                </button>
                            ) : teamScore.questions.find(
                                  q => q.question === question
                              )?.correct === false ? (
                                <button
                                    className="incorrect"
                                    onClick={() =>
                                        updateScoring(question, true)
                                    }
                                >
                                    {question}
                                </button>
                            ) : (
                                <button
                                    onClick={() =>
                                        updateScoring(question, true)
                                    }
                                >
                                    {question}
                                </button>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <Loading />
            )}
        </div>
    )
}
