import {
    collection,
    doc,
    addDoc,
    getDocs,
    updateDoc,
    QuerySnapshot,
    query,
    where,
    FieldPath,
    deleteDoc,
    documentId,
} from "firebase/firestore"
import { db } from "../firebase"

const dataService = {
    getData: async (collectionName, setLoading) => {
        setLoading(true)
        let newData
        await getDocs(collection(db, collectionName)).then(QuerySnapshot => {
            newData = QuerySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setLoading(false)
        })
        return newData
    },
    updateField: async (id, key, value, setter, data) => {
        setter(
            data.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        [key]: value,
                    }
                }
                return item
            })
        )
    },
    saveField: async (id, key, value, collection, updating) => {
        updating(true)
        const dataRef = doc(db, collection, id)
        await updateDoc(dataRef, {
            [key]: value,
        })
        updating(false)
    },
    deleteDoc: async (id, collection, setter, data) => {
        setter(data.filter(item => item.id !== id))
        await deleteDoc(doc(db, collection, id))
    },
    getScoresForTeam: async teamID => {
        const q = query(collection(db, "scores"), where("team", "==", teamID))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    getTeam: async teamID => {
        const q = query(
            collection(db, "teams"),
            where("__name__", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    getTeamGames: async teamID => {
        const q = query(
            collection(db, "gameteams"),
            where("team", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        const teamGames = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const uniqueGameIDs = [...new Set(teamGames.map(game => game.game))]
        if (uniqueGameIDs.length === 0) return []
        const q2 = query(
            collection(db, "games"),
            where(documentId(), "in", uniqueGameIDs)
        )
        /*
         the line above gave me an error f

            

         */
        const querySnapshot2 = await getDocs(q2)
        const games = querySnapshot2.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        // return only the teamGames whose games are finalized
        return teamGames.filter(teamGame =>
            games.find(
                game => game.id === teamGame.game && game.status === "finalized"
            )
        )
    },
    getChampionship: async seasonID => {
        const q = query(
            collection(db, "championships"),
            where("season", "==", seasonID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
    },
    getTeamRank: async (teamID, seasonID) => {
        const q = query(
            collection(db, "scores"),
            where("season", "==", seasonID)
        )
        const querySnapshot = await getDocs(q)
        const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const uniqueTeamIDs = [...new Set(scores.map(score => score.team))]
        const teamScores = uniqueTeamIDs.map(teamID => ({
            teamID,
            total: scores
                .filter(score => score.team === teamID)
                .reduce((acc, score) => acc + score.score, 0),
        }))
        const sortedTeamScores = teamScores.sort((a, b) => b.total - a.total)
        const teamRank = sortedTeamScores.findIndex(
            teamScore => teamScore.teamID === teamID
        )
        return {
            currentRank: teamRank + 1,
            totalTeams: sortedTeamScores.length,
        }
    },
    getGameTeams: async gameID => {
        const q = query(
            collection(db, "gameteams"),
            where("game", "==", gameID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            displayName: "",
            ...doc.data(),
        }))
    },
    getGameRounds: async gameID => {
        const q = query(collection(db, "rounds"), where("game", "==", gameID))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    getGameScores: async gameID => {
        const q = query(
            collection(db, "gamescores"),
            where("game", "==", gameID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    deleteGameTeam: async (gameID, teamID) => {
        const q = query(
            collection(db, "gameteams"),
            where("game", "==", gameID),
            where("team", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        querySnapshot.docs.map(doc => deleteDoc(doc.ref))
    },
    getGame: async gameID => {
        const q = query(
            collection(db, "games"),
            where("__name__", "==", gameID)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
    },
    getGameDetails: async gameID => {
        const game = await dataService.getGame(gameID)
        const gameTeams = await dataService.getGameTeams(gameID)
        const gameRounds = await dataService.getGameRounds(gameID)
        const gameScores = await dataService.getGameScores(gameID)
        return {
            game,
            gameTeams,
            gameRounds,
            gameScores,
        }
    },
    getGameUsersAndVenues: async games => {
        const uniqueUserIDs = [...new Set(games.map(game => game.user))]
        if (uniqueUserIDs.length === 0) return { users: [], venues: [] }
        const q = query(
            collection(db, "users"),
            where("uid", "in", uniqueUserIDs)
        )
        const querySnapshot2 = await getDocs(q)
        const users = querySnapshot2.docs.map(doc => ({
            id: doc.data().uid,
            ...doc.data(),
        }))
        const uniqueVenueIDs = [...new Set(games.map(game => game.venue))]
        if (uniqueVenueIDs.length === 0) return { users, venues: [] }
        const q3 = query(
            collection(db, "venues"),
            where(documentId(), "in", uniqueVenueIDs)
        )
        const querySnapshot3 = await getDocs(q3)
        const venues = querySnapshot3.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        return { users, venues }
    },
    deleteGame: async gameID => {
        const gameTeams = await dataService.getGameTeams(gameID)
        gameTeams.map(gameTeam =>
            dataService.deleteGameTeam(gameID, gameTeam.team)
        )
        const gameRounds = await dataService.getGameRounds(gameID)
        gameRounds.map(round => deleteDoc(doc(db, "rounds", round.id)))
        const gameScores = await dataService.getGameScores(gameID)
        gameScores.map(score => deleteDoc(doc(db, "gamescores", score.id)))
        deleteDoc(doc(db, "games", gameID))
        return true
    },
    findScore: async (teamID, date, venue) => {
        const q = query(
            collection(db, "scores"),
            where("team", "==", teamID),
            where("date", "==", date),
            where("venue", "==", venue)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]
    },
    getNextTeam: async teamID => {
        // we need to get all the teams and sort them by score and then return the next team
        // we need to filter the scores to the current season

        // get the current season
        const q = query(collection(db, "seasons"), where("active", "==", true))
        const querySnapshot = await getDocs(q)
        const currentSeason = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]

        const q2 = query(
            collection(db, "scores"),
            where("season", "==", currentSeason.id)
        )
        const querySnapshot2 = await getDocs(q2)
        const scores = querySnapshot2.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        const uniqueTeamIDs = [...new Set(scores.map(score => score.team))]
        const teamScores = uniqueTeamIDs.map(teamID => ({
            teamID,
            total: scores
                .filter(score => score.team === teamID)
                .reduce((acc, score) => acc + score.score, 0),
        }))
        const sortedTeamScores = teamScores.sort((a, b) => b.total - a.total)
        const teamRank = sortedTeamScores.findIndex(
            teamScore => teamScore.teamID === teamID
        )
        return sortedTeamScores[teamRank + 1]
    },
    getPrevTeam: async teamID => {
        // we need to get all the teams and sort them by score and then return the next team
        // we need to filter the scores to the current season

        // get the current season
        const q = query(collection(db, "seasons"), where("active", "==", true))
        const querySnapshot = await getDocs(q)
        const currentSeason = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0]

        const q2 = query(
            collection(db, "scores"),
            where("season", "==", currentSeason.id)
        )
        const querySnapshot2 = await getDocs(q2)
        const scores = querySnapshot2.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        const uniqueTeamIDs = [...new Set(scores.map(score => score.team))]
        const teamScores = uniqueTeamIDs.map(teamID => ({
            teamID,
            total: scores
                .filter(score => score.team === teamID)
                .reduce((acc, score) => acc + score.score, 0),
        }))
        const sortedTeamScores = teamScores.sort((a, b) => b.total - a.total)
        const teamRank = sortedTeamScores.findIndex(
            teamScore => teamScore.teamID === teamID
        )
        return sortedTeamScores[teamRank - 1]
    },
    getTeamCategoryCorrectCount: async teamID => {
        // first we have to get all games for the team by looking at gameteams
        const q = query(
            collection(db, "gameteams"),
            where("team", "==", teamID)
        )
        const querySnapshot = await getDocs(q)
        const gameTeams = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const uniqueGameIDs = [
            ...new Set(gameTeams.map(gameTeam => gameTeam.game)),
        ]
        // for each of the games, we need to get all of the rounds, we don't really care what games the rounds are associated with yet, that will be used later
        if (uniqueGameIDs.length === 0) return []
        const q2 = query(
            collection(db, "rounds"),
            where("game", "in", uniqueGameIDs)
        )
        const querySnapshot2 = await getDocs(q2)
        const rounds = querySnapshot2.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const uniqueCategoryIDs = [
            ...new Set(rounds.map(round => round.category)),
        ]
        // now we have all the categories for the team
        // we need to get all the gamescores for the team, which have a game property, a team property, and a round property. there is also an array of questions, each with 2 properties, correct: boolean, and queestion: number - we don't care about the question number
        const q3 = query(
            collection(db, "gamescores"),
            where("team", "==", teamID)
        )
        const querySnapshot3 = await getDocs(q3)
        const gameScores = querySnapshot3.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        // we need to get the correct count for each category
        const categoryCorrectCounts = uniqueCategoryIDs.map(categoryID => {
            const categoryRounds = rounds.filter(
                round => round.category === categoryID
            )
            const categoryGameScores = gameScores.filter(gameScore =>
                categoryRounds.find(round => round.id === gameScore.round)
            )
            const correctCount = categoryGameScores.reduce((acc, gameScore) => {
                return (
                    acc +
                    gameScore.questions.filter(question => question.correct)
                        .length
                )
            }, 0)
            const incorrectCount = categoryGameScores.reduce(
                (acc, gameScore) => {
                    return (
                        acc +
                        gameScore.questions.filter(
                            question => !question.correct
                        ).length
                    )
                },
                0
            )
            return {
                category: categoryID,
                correctCount,
                incorrectCount,
            }
        })

        return categoryCorrectCounts
    },
    getFromWhere: async (collectionName, field, value) => {
        const q = query(
            collection(db, collectionName),
            where(field, "==", value)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
    },
    /*
        given an array of score objects, find the other score objects for that date, venue and season
        and then return for each score object, where that team placed compared to the other score objects for that date, venue & season
    */
    getTeamRanks: async teamScores => {
        if (teamScores.length === 0) return []
        const q = query(
            collection(db, "scores"),
            where("season", "==", teamScores[0].season)
        )

        const querySnapshot = await getDocs(q)

        const scores = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))

        teamScores
            .sort((a, b) => b.date - a.date)
            // only the first 10 scores
            .slice(0, 10)
            .map(async teamScore => {
                // now get all of the other scores that aren't this team, but are for the same date, venue and season

                const teamRank =
                    scores.filter(
                        score =>
                            score.date.seconds === teamScore.date.seconds &&
                            score.venue === teamScore.venue &&
                            score.team !== teamScore.team &&
                            score.score > teamScore.score
                    ).length + 1

                teamScore.rank = teamRank
                teamScore.totalTeams = scores.filter(
                    score =>
                        score.date.seconds === teamScore.date.seconds &&
                        score.venue === teamScore.venue
                ).length
            })

        return teamScores
    },
}

export default dataService
