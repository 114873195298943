import { Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import dataService from "../data/dataService"
import moment from "moment"

import styled from "styled-components"

import "./Team.css"
import "./TeamMobile.css"

import { RadarChart } from "./RadarChart"
import { Medal } from "../components/Medal"
import { auth, db } from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { collection, getDocs, query, where } from "firebase/firestore"
import { IoIosShareAlt } from "react-icons/io"
import { IoCaretForward, IoCaretDown } from "react-icons/io5"

import useCategoryStore from "../data/categoryStore"
import useBadgeStore from "../data/badgeStore"
import useTeamStore from "../data/teamStore"

function ordinal_suffix_of(i) {
    if (i === undefined) return ""
    var j = i % 10,
        k = i % 100
    if (j === 1 && k !== 11) {
        return i + "st"
    }
    if (j === 2 && k !== 12) {
        return i + "nd"
    }
    if (j === 3 && k !== 13) {
        return i + "rd"
    }
    return i + "th"
}

export default function TeamPage() {
    const [user] = useAuthState(auth)
    const [userData, setUserData] = useState({})
    const [scores, setScores] = useState([])
    const [team, setTeam] = useState({})
    const [venues, setVenues] = useState([])
    const [seasons, setSeasons] = useState([])
    const [currentRank, setCurrentRank] = useState(0)
    const [totalTeams, setTotalTeams] = useState(0)
    const [currentSeason, setCurrentSeason] = useState({})
    const [teamGames, setTeamGames] = useState([])
    const [nextTeam, setNextTeam] = useState([])
    const [prevTeam, setPrevTeam] = useState([])
    const [teamStats, setTeamStats] = useState([])

    const [showBadges, setShowBadges] = useState(true)
    const [showMedals, setShowMedals] = useState(true)
    const [showChart, setShowChart] = useState(true)
    const [showScores, setShowScores] = useState(true)

    /*

    I need to get first get all gamescores for the team


    */
    const [teamData, setTeamData] = useState([])
    const params = useParams()

    useEffect(() => {
        dataService.getData("seasons", () => {}).then(setSeasons)
        dataService.getScoresForTeam(params.id).then(setScores)
        dataService.getData("venues", () => {}).then(setVenues)
        dataService.getTeam(params.id).then(setTeam)
        // we can use the number of games to calculate a medal
        dataService.getTeamGames(params.id).then(setTeamGames)
        dataService.getNextTeam(params.id).then(setNextTeam)
        dataService.getPrevTeam(params.id).then(setPrevTeam)
        dataService
            .getTeamCategoryCorrectCount(params.id)
            .then(processTeamStats)
        useBadgeStore.getState().getTeamBadges(params.id)
    }, [params])

    useEffect(() => {
        // dataService.getTeamRanks(scores).then(updatedScores => {
        //     setScores(updatedScores)
        // })
    }, [scores])

    const processTeamStats = stats => {
        // go through each category and make sure it is accounted for in teamStats, if not, add it as an object with the category, correctCount, and incorrectCount both set to 0
        useCategoryStore.getState().categories.forEach(category => {
            if (!stats.find(stat => stat.category === category.id)) {
                stats.push({
                    category: category.id,
                    correctCount: 0,
                    incorrectCount: 0,
                })
            }
        })
        setTeamStats(stats.filter(stat => stat.category !== ""))
    }

    const collectTeamData = () => {
        //  return an array of the percentage of correct answers for each category
        /*

        the numbers need to be in this order
        "History",
                "Science",
                "Music",
                "Movies",
                "Places",
                "Sports",
                "Mystery",
                "Pop Culture",

        */

        const data = []
        const categoryOrder = [
            "History",
            "Science",
            "Music",
            "Movies",
            "Places",
            "Sports",
            "Mystery",
            "Pop Culture",
        ]
        teamStats.forEach(stat => {
            const category = useCategoryStore
                .getState()
                .categories.find(c => c.id === stat.category)
            if (!category || !categoryOrder.includes(category.name)) {
                return
            }
            console.log(category.name, stat)
            data.push({
                category: category.name,
                percentage:
                    stat.correctCount === 0 && stat.incorrectCount === 0
                        ? 0
                        : (stat.correctCount /
                              (stat.correctCount + stat.incorrectCount)) *
                          100,
            })
        })
        data.sort((a, b) => {
            return (
                categoryOrder.indexOf(a.category) -
                categoryOrder.indexOf(b.category)
            )
        })
        return data.map(item => (isNaN(item.percentage) ? 0 : item.percentage))
    }

    useEffect(() => {
        const currentSeason = seasons.find(season => season.active === true)
        if (currentSeason !== undefined) {
            dataService.getTeamRank(params.id, currentSeason.id).then(rank => {
                setCurrentRank(rank.currentRank)
                setTotalTeams(rank.totalTeams)
                setCurrentSeason(currentSeason)
            })
        }
    }, [seasons, scores, params])

    useEffect(() => {
        dataService.getData("seasons", () => {}).then(setSeasons)
        getUserData()
    }, [user])

    const getUserData = async () => {
        if (user) {
            const q = query(
                collection(db, "users"),
                where("uid", "==", user.uid)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                setUserData(doc.data())
            })
        }
    }

    const processWorstOrBestScore = (score, scores, season) => {
        const sortedScores = scores
            .filter(score => score.season === season.id)
            .sort((a, b) => b.date - a.date)
            .slice(0, 10)
            .sort((a, b) => b.score - a.score)

        if (score.score === sortedScores[0].score) {
            return "best"
        } else if (
            score.score === sortedScores[sortedScores.length - 1].score
        ) {
            return "worst"
        } else {
            return ""
        }
    }

    return (
        team &&
        team.length > 0 && (
            <>
                <section>
                    <header className="team-page-header">
                        <div className="team-page-header-left">
                            <div className="team-page-header-rank">
                                <span className="current-rank">
                                    {currentRank > 0
                                        ? ordinal_suffix_of(currentRank)
                                        : "N/A"}
                                </span>
                                {prevTeam !== undefined && currentRank > 0 && (
                                    <Link
                                        className="prev-team"
                                        to={`/team/${prevTeam.teamID}`}
                                    >
                                        Prev
                                    </Link>
                                )}
                                {nextTeam !== undefined && currentRank > 0 && (
                                    <Link
                                        className="next-team"
                                        to={`/team/${nextTeam.teamID}`}
                                    >
                                        Next
                                    </Link>
                                )}
                            </div>
                            {currentRank > 0 ? (
                                <span className="total-teams">
                                    <Link
                                        to={`/standings/${currentSeason.slug}`}
                                    >
                                        out of {totalTeams} teams
                                    </Link>
                                </span>
                            ) : (
                                <span className="total-teams">N/A</span>
                            )}
                        </div>
                        <h1 className="team-name">
                            <span className="team-name-team-text">
                                {team[0].name}
                            </span>
                        </h1>
                        <button
                            className="share-button"
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    window.location.href
                                )
                                alert("Team page copied to clipboard!")
                            }}
                        >
                            <IoIosShareAlt className="share-button-icon" />
                        </button>
                    </header>
                    <div className="team-page-content">
                        {(useBadgeStore.getState().teamBadges.length > 0 ||
                            useBadgeStore
                                .getState()
                                .badges.some(badge => badge.earnable)) && (
                            <div className="team-page-stats-badges team-page-content-section">
                                <h2
                                    className="team-page-clickable-header"
                                    onClick={() => setShowBadges(!showBadges)}
                                >
                                    Badges
                                    <span className="team-page-stats-toggle">
                                        {showBadges ? (
                                            <IoCaretForward />
                                        ) : (
                                            <IoCaretDown />
                                        )}
                                    </span>
                                </h2>
                                {showBadges && (
                                    <ul className="badge-list">
                                        {useBadgeStore
                                            .getState()
                                            .teamBadges.sort(
                                                (a, b) =>
                                                    useBadgeStore
                                                        .getState()
                                                        .badges.find(
                                                            badge =>
                                                                badge.id ===
                                                                a.badge
                                                        ).earnable -
                                                    useBadgeStore
                                                        .getState()
                                                        .badges.find(
                                                            badge =>
                                                                badge.id ===
                                                                b.badge
                                                        ).earnable
                                            )
                                            .map(badge => (
                                                <li
                                                    className={`badge earned ${
                                                        useBadgeStore
                                                            .getState()
                                                            .badges.find(
                                                                b =>
                                                                    b.id ===
                                                                    badge.badge
                                                            ).earnable
                                                            ? "earnable"
                                                            : "not-earnable"
                                                    }`}
                                                    key={badge.id}
                                                >
                                                    <BadgeFront
                                                        className="badge-front"
                                                        image={`/images/badges/${
                                                            useBadgeStore
                                                                .getState()
                                                                .badges.find(
                                                                    b =>
                                                                        b.id ===
                                                                        badge.badge
                                                                ).image
                                                        }`}
                                                    >
                                                        <span className="badge-name">
                                                            {
                                                                useBadgeStore
                                                                    .getState()
                                                                    .badges.find(
                                                                        b =>
                                                                            b.id ===
                                                                            badge.badge
                                                                    ).name
                                                            }
                                                        </span>
                                                    </BadgeFront>
                                                    <span className="badge-back">
                                                        <span className="badge-description">
                                                            {
                                                                useBadgeStore
                                                                    .getState()
                                                                    .badges.find(
                                                                        b =>
                                                                            b.id ===
                                                                            badge.badge
                                                                    )
                                                                    .description
                                                            }
                                                        </span>
                                                        <span className="badge-name">
                                                            {
                                                                useBadgeStore
                                                                    .getState()
                                                                    .badges.find(
                                                                        b =>
                                                                            b.id ===
                                                                            badge.badge
                                                                    ).name
                                                            }
                                                        </span>
                                                        <span className="badge-date">
                                                            {moment(
                                                                badge.earned
                                                            ).format(
                                                                "MMM Do, YYYY"
                                                            )}
                                                        </span>
                                                        {useBadgeStore
                                                            .getState()
                                                            .badges.find(
                                                                b =>
                                                                    b.id ===
                                                                    badge.badge
                                                            ).earnable && (
                                                            <span className="badge-earned">
                                                                {
                                                                    useTeamStore
                                                                        .getState()
                                                                        .teams.find(
                                                                            t =>
                                                                                t.id ===
                                                                                useBadgeStore
                                                                                    .getState()
                                                                                    .badges.find(
                                                                                        b =>
                                                                                            b.id ===
                                                                                            badge.badge
                                                                                    )
                                                                                    .team
                                                                        ).name
                                                                }
                                                            </span>
                                                        )}
                                                    </span>
                                                </li>
                                            ))}
                                        {useBadgeStore
                                            .getState()
                                            .badges.filter(
                                                badge =>
                                                    badge.earnable &&
                                                    badge.team !== team[0].id &&
                                                    useBadgeStore
                                                        .getState()
                                                        .teamBadges.find(
                                                            b =>
                                                                b.badge ===
                                                                badge.id
                                                        ) === undefined
                                            )
                                            .map(badge => (
                                                <li
                                                    className={`badge not-earned ${
                                                        badge.earnable
                                                            ? "earnable"
                                                            : "not-earnable"
                                                    }`}
                                                    key={badge.id}
                                                >
                                                    <BadgeFront
                                                        className="badge-front"
                                                        image={`/images/badges/${badge.image}`}
                                                    >
                                                        <span className="badge-name">
                                                            {badge.name}
                                                        </span>
                                                    </BadgeFront>
                                                    <span className="badge-back">
                                                        <span className="badge-description">
                                                            {badge.description}
                                                        </span>
                                                        <span className="badge-name">
                                                            {badge.name}
                                                        </span>
                                                        <span>
                                                            {scores.reduce(
                                                                (acc, score) =>
                                                                    acc +
                                                                    score.score,
                                                                0
                                                            ) +
                                                                teamStats.reduce(
                                                                    (
                                                                        acc,
                                                                        stat
                                                                    ) =>
                                                                        acc +
                                                                        stat.correctCount,
                                                                    0
                                                                )}{" "}
                                                            / 2,693
                                                        </span>
                                                        <span className="badge-earned">
                                                            {
                                                                useTeamStore
                                                                    .getState()
                                                                    .teams.find(
                                                                        t =>
                                                                            t.id ===
                                                                            badge.team
                                                                    ).name
                                                            }
                                                        </span>
                                                    </span>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                        )}
                        <div className="team-page-stats-medals team-page-content-section">
                            <h2
                                className="team-page-clickable-header"
                                onClick={() => setShowMedals(!showMedals)}
                            >
                                Medals
                                <span className="team-page-stats-toggle">
                                    {showMedals ? (
                                        <IoCaretForward />
                                    ) : (
                                        <IoCaretDown />
                                    )}
                                </span>
                            </h2>
                            {showMedals && (
                                <ul className="medal-list">
                                    <Medal
                                        name="Games Played"
                                        value={teamGames.length + scores.length}
                                    />
                                    <Medal
                                        name="Total Score"
                                        value={
                                            scores.reduce(
                                                (acc, score) =>
                                                    acc + score.score,
                                                0
                                            ) +
                                            teamStats.reduce(
                                                (acc, stat) =>
                                                    acc + stat.correctCount,
                                                0
                                            )
                                        }
                                    />
                                    <hr />
                                    {useCategoryStore
                                        .getState()
                                        .categories.map(category => (
                                            <Medal
                                                name={category.name}
                                                key={category.id}
                                                value={
                                                    teamStats.find(
                                                        stat =>
                                                            stat.category ===
                                                            category.id
                                                    )?.correctCount || 0
                                                }
                                            />
                                        ))}
                                </ul>
                            )}
                        </div>
                        <section className="team-page-stats team-page-content-section">
                            <h2
                                className="team-page-clickable-header"
                                onClick={() => setShowChart(!showChart)}
                            >
                                Category Accuracy
                                <span className="team-page-stats-toggle">
                                    {showChart ? (
                                        <IoCaretForward />
                                    ) : (
                                        <IoCaretDown />
                                    )}
                                </span>
                            </h2>
                            {showChart && (
                                <RadarChart teamData={collectTeamData()} />
                            )}
                        </section>
                        <div
                            className={`team-page-season-header team-page-content-section`}
                        >
                            <h2
                                className="team-page-clickable-header"
                                onClick={() => setShowScores(!showScores)}
                            >
                                Recent Games
                                <span className="team-page-stats-toggle">
                                    {showScores ? (
                                        <IoCaretForward />
                                    ) : (
                                        <IoCaretDown />
                                    )}
                                </span>
                            </h2>
                            {showScores && (
                                <ul className="teams-page-scores-list">
                                    {scores
                                        .sort((a, b) =>
                                            // use moment to sort by date
                                            moment(b.date.toDate()).diff(
                                                moment(a.date.toDate())
                                            ) > 0
                                                ? 1
                                                : -1
                                        )
                                        .map(
                                            (score, i) =>
                                                i < 10 && (
                                                    <li
                                                        key={score.id}
                                                        className={`team-score-page-score rank-${score.rank}`}
                                                    >
                                                        <span className="score-score">
                                                            {score.score}
                                                        </span>
                                                        <span className="score-date-venue-wrapper">
                                                            <span className="score-venue">
                                                                {
                                                                    venues.find(
                                                                        venue =>
                                                                            venue.id ===
                                                                            score.venue
                                                                    )?.name
                                                                }
                                                            </span>
                                                            <span className="score-date">
                                                                {moment(
                                                                    score.date.toDate()
                                                                ).format(
                                                                    "MMM Do"
                                                                )}
                                                            </span>
                                                            <span className="score-rank">
                                                                {ordinal_suffix_of(
                                                                    score.rank
                                                                )}
                                                            </span>
                                                        </span>
                                                    </li>
                                                )
                                        )}
                                </ul>
                            )}
                        </div>
                    </div>
                </section>
                {prevTeam !== undefined && (
                    <Link
                        className="prev-team-side"
                        to={`/team/${prevTeam.teamID}`}
                    >
                        {currentRank - 1 > 0
                            ? ordinal_suffix_of(currentRank - 1)
                            : ""}
                    </Link>
                )}
                {nextTeam !== undefined && (
                    <Link
                        className="next-team-side"
                        to={`/team/${nextTeam.teamID}`}
                    >
                        {currentRank + 1 > 0
                            ? ordinal_suffix_of(currentRank + 1)
                            : ""}
                    </Link>
                )}
            </>
        )
    )
}

const BadgeFront = styled.span`
    background-image: url(${props => props.image});
`
