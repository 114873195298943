import create from "zustand"
import dataService from "./dataService"

const useBadgeStore = create(set => ({
    badges: [],
    teamID: false,
    teamBadges: [],
    setBadges: badges => set({ badges }),
    getBadges: () => {
        dataService
            .getData("badges", () => {})
            .then(data => {
                set({ badges: data })
            })
    },
    setTeamBadges: teamBadges => set({ teamBadges }),
    getTeamBadges: teamID => {
        dataService.getFromWhere("teambadges", "team", teamID).then(data => {
            set({ teamBadges: data, teamID })
        })
    },
}))

export default useBadgeStore
