import create from "zustand"
import dataService from "./dataService"

const useTeamStore = create(set => ({
    teams: [],
    setTeams: teams => set({ teams }),
    getTeams: () => {
        dataService
            .getData("teams", () => {})
            .then(data => {
                set({ teams: data })
            })
    },
}))

export default useTeamStore