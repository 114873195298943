import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import { collection, doc, addDoc, getDocs, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"

import dataService from "../../data/dataService"

export default function SeasonsAdmin() {
    const [seasons, setSeasons] = useState([])
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [query, setQuery] = useState("")

    useEffect(() => {
        dataService.getData("seasons", setLoading).then(setSeasons)
    }, [])

    const addSeason = async e => {
        e.preventDefault()
        setUpdating(true)
        const name = e.target.name.value

        const seasonRef = await addDoc(collection(db, "seasons"), {
            name,
        })

        setSeasons([
            ...seasons,
            {
                id: seasonRef.id,
                name,
            },
        ])
        setUpdating(false)
        e.target.reset()
    }

    const search = e => {
        const keyword = e.target.value
        setQuery(keyword)
    }

    return (
        <section className="seasons-admin-page">
            <h1>Seasons</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            <p>{loading && <span>Seasons: Loading...</span>}</p>
            <p>{updating && <span>Seasons: Updating...</span>}</p>
            <section>
                <input
                    type="text"
                    placeholder="Search Seasons...."
                    onChange={search}
                />
            </section>
            <details>
                <summary>Add Season</summary>
                <form onSubmit={addSeason}>
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" />
                    <button type="submit">Add Season</button>
                </form>
            </details>
            {seasons && (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Active</th>
                            <th>Slug</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {seasons
                            .filter(season =>
                                query !== ""
                                    ? season.name
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                    : true
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(season => (
                                <tr key={season.id}>
                                    <td>
                                        <input
                                            type="text"
                                            value={season.name}
                                            onChange={e =>
                                                dataService.updateField(
                                                    season.id,
                                                    "name",
                                                    e.target.value,
                                                    setSeasons,
                                                    seasons
                                                )
                                            }
                                            onBlur={e =>
                                                dataService.saveField(
                                                    season.id,
                                                    "name",
                                                    e.target.value,
                                                    "seasons",
                                                    setUpdating
                                                )
                                            }
                                        />
                                    </td>
                                    <td>{season.active ? "Yes" : "No"}</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={season.slug}
                                            onChange={e =>
                                                dataService.updateField(
                                                    season.id,
                                                    "slug",
                                                    e.target.value,
                                                    setSeasons,
                                                    seasons
                                                )
                                            }
                                            onBlur={e =>
                                                dataService.saveField(
                                                    season.id,
                                                    "slug",
                                                    e.target.value,
                                                    "seasons",
                                                    setUpdating
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="date"
                                            value={season.startDate}
                                            onChange={e =>
                                                dataService.updateField(
                                                    season.id,
                                                    "startDate",
                                                    e.target.value,
                                                    setSeasons,
                                                    seasons
                                                )
                                            }
                                            onBlur={e =>
                                                dataService.saveField(
                                                    season.id,
                                                    "startDate",
                                                    e.target.value,
                                                    "seasons",
                                                    setUpdating
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="date"
                                            value={season.endDate}
                                            onChange={e =>
                                                dataService.updateField(
                                                    season.id,
                                                    "endDate",
                                                    e.target.value,
                                                    setSeasons,
                                                    seasons
                                                )
                                            }
                                            onBlur={e =>
                                                dataService.saveField(
                                                    season.id,
                                                    "endDate",
                                                    e.target.value,
                                                    "seasons",
                                                    setUpdating
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </section>
    )
}
