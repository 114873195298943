import React from "react"
import moment from "moment"

export default function LiveScoringHeader({
    handleGoBack,
    game,
    gameVenue,
    selectedDate,
    gameTeams,
    showTeams,
    setShowTeams,
    setCurrentRound,
    setShowScoreboard,
    calculateThirdLine,
    setCurrentTeam
}) {
    return (
        <header>
            <span className="close-game" onClick={handleGoBack}>
                &lt; Go Back
            </span>
            <h2>
                {game && gameVenue ? gameVenue.name : "Choose Venue"}
                <span>
                    {moment(game ? game.date : selectedDate).format(
                        "MMMM Do, YYYY"
                    )}
                </span>
                <span className="live-scoring-header-third-line">
                    {calculateThirdLine()}
                </span>
            </h2>
            {game.status === "created" ? (
                <button
                    className={`live-scoring-teams-button ${
                        showTeams ? "selected" : ""
                    }`}
                    onClick={() => {
                        setShowTeams(!showTeams)
                        setCurrentRound(0)
                        setCurrentTeam(null)
                        setShowScoreboard(false)
                    }}
                >
                    Teams ({gameTeams.length})
                </button>
            ) : (
                <span></span>
            )}
        </header>
    )
}
