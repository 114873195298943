import React from "react"

export default function ScoringList({
    currentRound,
    currentRoundCategory,
    gameTeams,
    gameScores,
    gameRounds,
    setScoring,
    teams,
    setScoringRound,
    setCurrentRound,
    currentTeam,
    setCurrentTeam,
    numberOfRoundsPerGame,
    questionsPerRound,
}) {
    // this is has been awesome, however due to jira story SMTRIV-94 we need to move team scoring to a new view
    // so we can have a better user experience
    // this is the new view that will be used for scoring teams
    // so this view needs to just be the list of teams, and then when you click on that, we need to set the current team in the parent component
    return (
        <ul className="live-scoring-list scoring-round">
            <h2>
                <span className="round-name-and-number">
                    Round {currentRound} -{" "}
                </span>
                <span
                    className="scoring-category"
                    onClick={() => {
                        setCurrentRound(currentRound)
                        setScoringRound(false)
                    }}
                >
                    {currentRoundCategory() || "Choose Category"}
                </span>{" "}
                - Scoring
            </h2>
            {gameTeams.length === 0 && (
                <h2>
                    Please add some teams using the Teams button in the header
                </h2>
            )}
            {gameTeams.map(gameTeam => (
                <li
                    className={`scoring-team ${
                        gameScores
                            .filter(gs => gs.team === gameTeam.team)
                            .filter(
                                gs =>
                                    gs.round ===
                                    gameRounds.find(
                                        r => r.round === currentRound
                                    ).id
                            )[0].touched
                            ? ""
                            : "missing"
                    }`}
                    key={gameTeam.team}
                    onClick={() => setCurrentTeam(gameTeam.team)}
                >
                    <p className="team-display">
                        <span className="display-name">
                            {gameTeam.displayName !== ""
                                ? gameTeam.displayName
                                : teams.find(team => team.id === gameTeam.team)
                                      ?.name}
                        </span>
                        <span className="team-name">
                            {
                                teams.find(team => team.id === gameTeam.team)
                                    ?.name
                            }
                        </span>
                    </p>
                    <span className="team-touched">
                        {gameScores
                            .filter(gs => gs.team === gameTeam.team)
                            .filter(
                                gs =>
                                    gs.round ===
                                    gameRounds.find(
                                        r => r.round === currentRound
                                    ).id
                            )[0].touched
                            ? ""
                            : "MISSING SCORE"}
                    </span>
                    {/* now let's count how many correct answers they have in this round */}
                    <span className="team-correct">
                        {gameScores
                            .filter(gs => gs.team === gameTeam.team)
                            .filter(
                                gs =>
                                    gs.round ===
                                    gameRounds.find(
                                        r => r.round === currentRound
                                    ).id
                            )
                            .sort((a, b) =>
                                a.questions.length > b.questions.length ? -1 : 1
                            )[0]
                            ?.questions.filter(q => q.correct).length || 0}
                        /{questionsPerRound[currentRound - 1]} Correct
                    </span>
                </li>
            ))}
        </ul>
    )
}
