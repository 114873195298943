import React, { useEffect } from "react"
import moment from "moment"

export default function RoundList({
    gameRounds,
    gameScores,
    gameTeams,
    categories,
    processCurrentRound,
    numberOfRoundsPerGame,
    questionsPerRound,
}) {
    return (
        <ul className="live-scoring-list">
            {gameRounds.map((round, i) =>
                i < numberOfRoundsPerGame ? (
                    <li
                        key={round.round}
                        onClick={() => processCurrentRound(round.round)}
                    >
                        <span className="round-item-label">
                            Round {round.round}
                        </span>
                        <span className="round-item-category">
                            {round.category !== ""
                                ? categories.find(
                                      cat => cat.id === round.category
                                  )?.name
                                : ""}
                        </span>
                        <span className="round-item-score">
                            {
                                gameScores
                                    .filter(gs => gs.round === round.id)
                                    .filter((value, index, self) => {
                                        return (
                                            self.findIndex(
                                                v => v.team === value.team
                                            ) === index
                                        )
                                    })
                                    .filter(gs => gs.touched).length
                            }
                            /{gameTeams.length}
                        </span>
                    </li>
                ) : null
            )}
        </ul>
    )
}
