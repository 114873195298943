import { Link } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import { useEffect, useState } from "react"
import dataService from "../data/dataService"
import Loading from "../components/Loading"

import "./Home.css"
import useTeamStore from "../data/teamStore"

export default function Home() {
    const [user] = useAuthState(auth)
    const [page, setPage] = useState(null)
    const [teams, setTeams] = useState([])

    useEffect(() => {
        dataService
            .getData("pages", () => {})
            .then(data => {
                setPage(data.find(page => page.slug === "/"))
            })
    }, [])

    const searchForTeam = e => {
        if (e.target.value.length > 2) {
            setTeams(
                useTeamStore
                    .getState()
                    .teams.filter(team =>
                        team.name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
            )
        } else {
            setTeams([])
        }
    }

    return page ? (
        <section className={`bg-${page.color}`}>
            <header>
                <h1>{page.title}</h1>
            </header>
            <h2>{page.subheadline}</h2>
            <p>{page.content}</p>
            <p className="search-for-team">
                <input
                    type="text"
                    placeholder="Search for a team"
                    className="team-search-box"
                    onChange={searchForTeam}
                />
                {teams.length > 0 && (
                    <div className="search-results">
                        {teams.map(team => (
                            <Link key={team.id} to={`/team/${team.id}`}>
                                {team.name}
                            </Link>
                        ))}
                    </div>
                )}
            </p>
            <section className="button-section">
                <Link to="/standings/2024-winter">
                    <b>Latest Scores</b>
                </Link>
                {!user && (
                    <div className="register-and-login">
                        <Link to="/register">
                            <i>Register</i>
                        </Link>
                        <Link to="/login">
                            <b>Login</b>
                        </Link>
                    </div>
                )}
            </section>
        </section>
    ) : (
        <Loading />
    )
}
