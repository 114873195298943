import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import moment from "moment"

import dataService from "../../data/dataService"
import "./LiveScoringAdmin.css"

import { Timestamp } from "firebase/firestore"

export default function GamesAdmin() {
    const [games, setGames] = useState([])
    const [hosts, setHosts] = useState([])
    const [venues, setVenues] = useState([])
    const [edit, setEdit] = useState(false)
    const navigate = useNavigate()

    const updateVenue = e => {
        if (edit.venue !== e.target.value) {
            // first we need to confirm that change this will change all of the scores on the scoreboard as well
            const confirmChange = window.confirm(
                "Are you sure you want to change the venue for this game? This will also change the venue for all scores on the scoreboard for the teams that attended this game. (most likely yes)"
            )
            if (confirmChange) {
                // first we need to update the game
                updateField(e, "venue")
                dataService.getGameTeams(edit.id).then(gameTeams => {
                    gameTeams.forEach(gameTeam => {
                        dataService
                            .findScore(
                                gameTeam.team,
                                Timestamp.fromDate(moment(edit.date).toDate()),
                                edit.venue
                            )
                            .then(score => {
                                if (score) {
                                    dataService.saveField(
                                        score.id,
                                        "venue",
                                        e.target.value,
                                        "scores",
                                        () => {}
                                    )
                                }
                            })
                    })
                })
            } else {
                // reset the select back to the original value
                e.target.value = edit.venue
            }
        }
    }

    useEffect(() => {
        dataService
            .getData("games", () => {})
            .then(data => {
                setGames(
                    data.sort((a, b) => {
                        return moment(a.date).isBefore(b.date) ? 1 : -1
                    })
                )
            })

        dataService
            .getData("users", () => {})
            .then(users => {
                setHosts(
                    users
                        .filter(
                            user =>
                                user.role === "host" || user.role === "admin"
                        )
                        .map(user => {
                            return {
                                ...user,
                                id: user.uid,
                            }
                        })
                        .sort((a, b) => {
                            return a.name > b.name ? 1 : -1
                        })
                )
            })

        dataService
            .getData("venues", () => {})
            .then(venues => {
                setVenues(venues.sort((a, b) => (a.name > b.name ? 1 : -1)))
            })
    }, [])

    const editGame = gameID => {
        setEdit(games.find(game => game.id === gameID))
    }

    const viewGame = gameID => {
        // navigate to the game view page
        navigate(`/admin/games/${gameID}`)
    }

    const deleteGame = gameID => {
        // first let's confirm
        const confirmDelete = window.confirm(
            "Are you sure you want to delete this game?"
        )
        if (confirmDelete) {
            dataService.deleteGame(gameID).then(() => {
                setGames(games.filter(game => game.id !== gameID))
            })
        }
    }

    const updateField = (e, field) => {
        dataService.saveField(edit.id, field, e.target.value, "games", () => {})
        setEdit({ ...edit, [field]: e.target.value })
        setGames(
            games.map(game =>
                game.id === edit.id
                    ? { ...edit, [field]: e.target.value }
                    : game
            )
        )
    }

    return (
        <>
            <section className="live-scores-admin-page">
                <h1>Live Scoring Admin</h1>
                <p>Manage live scoring for games</p>
                {games.length > 0 ? (
                    <ul className="live-scores-games-list">
                        <header className="live-scores-games-list-header">
                            <span>Date</span>
                            <span>Status</span>
                            <span>Host</span>
                            <span>Venue</span>
                            <span>Actions</span>
                        </header>
                        {games.map(game => (
                            <li
                                className={`live-scores-game ${game.status}`}
                                key={game.id}
                            >
                                {/* the date should be Friday, May 25th, 2024 */}
                                <h2>
                                    {moment(game.date).format(
                                        "dddd, MMMM Do, YYYY"
                                    )}
                                </h2>
                                <p>{game.status}</p>
                                <p>
                                    {hosts.length > 0 &&
                                        hosts.find(u => u.id === game.user)
                                            ?.name}
                                </p>
                                <p>
                                    {venues.length > 0 &&
                                        venues.find(v => v.id === game.venue)
                                            ?.name}
                                </p>
                                <div className="live-scores-admin-actions">
                                    <button
                                        className="live-scores-game-edit"
                                        onClick={() => editGame(game.id)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="live-scores-game-view"
                                        onClick={() => viewGame(game.id)}
                                    >
                                        View
                                    </button>
                                    <button
                                        className="live-scores-game-delete"
                                        onClick={() => deleteGame(game.id)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No games found</p>
                )}
            </section>
            {edit && (
                <div className="live-scores-admin-edit">
                    <a
                        className="close"
                        href="/"
                        onClick={e => {
                            e.preventDefault()
                            setEdit(false)
                        }}
                    >
                        ❌
                    </a>
                    <h2>Edit Game</h2>
                    <p>
                        <label htmlFor="date">Date</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            defaultValue={edit.date}
                        />
                    </p>
                    <p>
                        <label htmlFor="status">Status</label>
                        <select
                            name="status"
                            id="status"
                            defaultValue={edit.status}
                            onChange={e => updateField(e, "status")}
                        >
                            <option value="created">Created</option>
                            <option value="finalized">Finalized ✅</option>
                        </select>
                    </p>
                    <p>
                        <label htmlFor="host">Host</label>
                        <select
                            name="host"
                            id="host"
                            defaultValue={edit.user}
                            onChange={e => updateField(e, "user")}
                        >
                            {hosts.length > 0 &&
                                hosts.map(host => (
                                    <option key={host.id} value={host.id}>
                                        {host.name}{" "}
                                        {host.role === "admin" && "(Admin)"}{" "}
                                        {host.role === "host" && "(Host)"}
                                    </option>
                                ))}
                        </select>
                    </p>
                    <p>
                        <label htmlFor="venue">Venue</label>
                        <select
                            name="venue"
                            id="venue"
                            defaultValue={edit.venue}
                            onChange={e => updateVenue(e)}
                        >
                            {venues.length > 0 &&
                                venues.map(venue => (
                                    <option key={venue.id} value={venue.id}>
                                        {venue.name}
                                    </option>
                                ))}
                        </select>
                    </p>
                    <p>
                        <button type="submit" onClick={() => setEdit(false)}>
                            Done
                        </button>
                    </p>
                </div>
            )}
        </>
    )
}
