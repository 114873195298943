import { addDoc, collection } from "firebase/firestore"
import React from "react"

import { db } from "../../firebase"

import "./TeamsList.css"
import dataService from "../../data/dataService"

export default function TeamsList({
    teams,
    setTeams,
    teamSearch,
    setTeamSearch,
    game,
    gameTeams,
    setGameTeams,
    addTeamToGame,
    removeTeamFromGame,
}) {
    const addNewTeam = async gameId => {
        if (!teamSearch) return

        // confirmation dialog

        if (!window.confirm(`Create new team: ${teamSearch}?`)) return

        const teamName = teamSearch
        const newTeam = await addDoc(collection(db, "teams"), {
            name: teamName,
        })
        addTeamToGame(newTeam.id, gameId)
        setTeams([...teams, { id: newTeam.id, name: teamName }])
    }

    const [editing, setEditing] = React.useState(false)

    const updateTeamName = (teamId, newName) => {
        // update the displayName property of the gameTeam and save it in the databsae, also call setGameTeams

        // update the database
        const gameTeam = gameTeams.find(gt => gt.team === teamId)
        dataService.updateField(
            gameTeam.id,
            "displayName",
            newName,
            setGameTeams,
            gameTeams
        )
        dataService.saveField(
            gameTeam.id,
            "displayName",
            newName,
            "gameteams",
            () => {}
        )
        setEditing(false)
    }

    return (
        <ul className="live-scoring-list">
            <input
                type="text"
                placeholder="Search Teams (3 characters minimum)"
                className="team-search"
                value={teamSearch}
                onChange={e => setTeamSearch(e.target.value)}
            />

            {teamSearch.length >= 3 &&
                teams
                    .filter(
                        team =>
                            team.name
                                .toLowerCase()
                                .includes(teamSearch.toLowerCase()) &&
                            !gameTeams.find(
                                gameTeam => gameTeam.team === team.id
                            )
                    )
                    .map(team => (
                        <li
                            onClick={() => addTeamToGame(team.id, game.id)}
                            key={team.id}
                        >
                            {team.name}
                        </li>
                    ))}

            {teamSearch.length >= 3 && (
                <li
                    onClick={() => addNewTeam(game.id)}
                    className="game-team-new"
                >
                    ➕ Create Team: {teamSearch}
                </li>
            )}

            {gameTeams.map(gameTeam => (
                <li key={gameTeam.team} className="game-team-selected">
                    <span
                        className="display-name"
                        onClick={() => setEditing(gameTeam.team)}
                    >
                        {editing === gameTeam.team ? (
                            <input
                                type="text"
                                defaultValue={gameTeam.displayName}
                                autoFocus
                                onBlur={e => {
                                    updateTeamName(
                                        gameTeam.team,
                                        e.target.value
                                    )
                                }}
                            />
                        ) : gameTeam.displayName !== "" ? (
                            gameTeam.displayName
                        ) : (
                            teams.find(team => team.id === gameTeam.team)?.name
                        )}
                    </span>
                    <span className="team-name">
                        {teams.find(team => team.id === gameTeam.team)?.name}
                    </span>
                    <span
                        className="remove-team"
                        onClick={() => removeTeamFromGame(gameTeam.team)}
                    >
                        ❌
                    </span>
                </li>
            ))}
        </ul>
    )
}
