import moment from "moment"
import React, { useState } from "react"

export default function ScoresWizardStep3({
    venues,
    setWizardStep,
    setSelectedSeason,
    setSelectedDate,
    setSelectedVenue,
    selectedDate,
    type = "scores",
}) {
    const [tmpVenue, setTmpVenue] = useState("")
    return (
        <section>
            <span
                className="close"
                onClick={() => {
                    setWizardStep(0)
                    setSelectedSeason("")
                    setSelectedDate("")
                    setSelectedVenue("")
                }}
            >
                x
            </span>
            <h1>{type === "game" ? "Create Game" : "Enter Scores"}</h1>
            <h2>Step 3: Venue</h2>
            {type === "game" ? (
                <p>Which venue are you creating a game for?</p>
            ) : (
                <p>Which venue are you entering scores for?</p>
            )}

            <p>
                {type === "game"
                    ? "You have selected to create a game for a"
                    : "You have selected to enter scores for a"}{" "}
                <strong>{moment(selectedDate).format("dddd")}</strong> the first
                set of venues are dedicated to{" "}
                {moment(selectedDate).format("dddd")}s
            </p>

            <div className="grid">
                {venues
                    .filter(
                        venue =>
                            venue.day === moment(selectedDate).format("dddd")
                    )
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map(venue => (
                        <div
                            className={`grid-item ${
                                tmpVenue === venue.id && "selected"
                            }`}
                            key={venue.id}
                            onClick={() => setTmpVenue(venue.id)}
                        >
                            {venue.name}
                        </div>
                    ))}
            </div>
            <p>
                Otherwise, select from these venues which are normally not on{" "}
                {moment(selectedDate).format("dddd")}s
            </p>
            <div className="grid">
                {venues
                    .filter(
                        venue =>
                            venue.day !== moment(selectedDate).format("dddd")
                    )
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map(venue => (
                        <div
                            className={`grid-item ${
                                tmpVenue === venue.id && "selected"
                            }`}
                            key={venue.id}
                            onClick={() => setTmpVenue(venue.id)}
                        >
                            {venue.name}
                        </div>
                    ))}
                {tmpVenue && (
                    <p>
                        <button
                            className="btn btn-success"
                            onClick={() => {
                                setWizardStep(4)
                                setSelectedVenue(tmpVenue)
                                setTmpVenue("")
                            }}
                        >
                            Next
                        </button>
                    </p>
                )}
            </div>
        </section>
    )
}
