import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import { collection, doc, addDoc, getDocs, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import PageForm from "./PageForm"

export default function PagesAdmin() {
    const [pages, setPages] = useState([])
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [query, setQuery] = useState("")
    const [editPage, setEditPage] = useState(null)

    const getPages = async () => {
        const pagesRef = collection(db, "pages")
        const pagesSnapshot = await getDocs(pagesRef)
        const pagesList = pagesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        setPages(pagesList)
    }

    useEffect(() => {
        setLoading(true)
        getPages()
        setLoading(false)
    }, [])

    const addPage = async e => {
        e.preventDefault()
        setUpdating(true)
        const pageRef = await addDoc(collection(db, "pages"), {
            title: e.target.title.value,
            subheadline: e.target.subheadline.value,
            slug: e.target.slug.value,
            color: e.target.color.value,
            content: e.target.content.value,
        })
        setPages([
            ...pages,
            {
                id: pageRef.id,
                title: e.target.title.value,
                subheadline: e.target.subheadline.value,
                slug: e.target.slug.value,
                color: e.target.color.value,
                content: e.target.content.value,
            },
        ])
        setUpdating(false)
    }

    const cancelEdit = () => {
        setEditPage(null)
    }

    const updatePage = async e => {
        e.preventDefault()
        setUpdating(true)
        const pageRef = doc(db, "pages", editPage.id)
        await updateDoc(pageRef, {
            title: e.target.title.value,
            subheadline: e.target.subheadline.value,
            slug: e.target.slug.value,
            color: e.target.color.value,
            content: e.target.content.value,
        })
        const updatedPages = pages.map(page => {
            if (page.id === editPage.id) {
                return {
                    id: editPage.id,
                    title: e.target.title.value,
                    subheadline: e.target.subheadline.value,
                    slug: e.target.slug.value,
                    color: e.target.color.value,
                    content: e.target.content.value,
                }
            }
            return page
        })
        setPages(updatedPages)
        setEditPage(null)
        setUpdating(false)
    }

    return (
        <section className="venues-admin-page">
            <h1>Pages</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            {pages && (
                <ul className="page-admin-page-list p-0 flex">
                    {pages.map(page => (
                        <li
                            key={page.id}
                            className={`page-box bg-${page.color} m-10 flex flex-column`}
                            onClick={() => setEditPage(page)}
                        >
                            <span>{page.title}</span>
                            <span className="f-sm">{page.slug}</span>
                        </li>
                    ))}
                </ul>
            )}

            <hr />
            <section className="flex flex-column">
                {editPage !== null ? <h2>Edit Page</h2> : <h2>Add Page</h2>}
                <PageForm
                    onSubmit={editPage !== null ? updatePage : addPage}
                    page={editPage}
                    onCancel={cancelEdit}
                />
            </section>
        </section>
    )
}
