export default function Loading() {
    return (
        <img
            src="/images/Crab-Logo-11-Random-BG.gif"
            alt="loading"
            className="loading"
            width="200"
            className="image-center"
        />
    )
}
