import { Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import dataService from "../data/dataService"
import { Timestamp } from "firebase/firestore"
import moment from "moment"
import { ChevronUpIcon } from "@heroicons/react/24/outline"

import "./Championship.css"

import "./Standings.css"
import Loading from "../components/Loading"

export default function Standings() {
    const [seasons, setSeasons] = useState([])
    const [season, setSeason] = useState({})
    const [scores, setScores] = useState([])
    const [teams, setTeams] = useState([])
    const [venues, setVenues] = useState([])
    const [teamIDs, setTeamIDs] = useState([])
    const [sbTeams, setSBTeams] = useState([])
    const [championship, setChampionship] = useState({})
    const [filteredDate, setFilteredDate] = useState(moment())
    const [sortBy, setSortBy] = useState("total")
    const params = useParams()

    useEffect(() => {
        dataService.getData("seasons", () => {}).then(setSeasons)
        dataService.getData("scores", () => {}).then(setScores)
        dataService.getData("teams", () => {}).then(setTeams)
        dataService.getData("venues", () => {}).then(setVenues)
    }, [])

    useEffect(() => {
        if (seasons.length > 0) {
            const season = seasons.find(season => season.slug === params.slug)
            setSeason(season)
            dataService.getChampionship(season.id).then(setChampionship)
        }
    }, [seasons, params.slug])

    useEffect(() => {
        if (season && season.id) {
            setTeamIDs([
                ...new Set(
                    scores
                        .filter(score => score.season === season.id)
                        .map(i => i.team)
                ),
            ])
        }
        if (scores && scores.length > 0) {
            setFilteredDate(
                Timestamp.fromDate(
                    moment(
                        scores
                            .filter(score => score.season === season.id)
                            .sort((a, b) => b.date - a.date)[0]
                            ?.date?.toDate() || moment().toDate()
                    ).toDate()
                )
            )
        }
    }, [season, scores])

    useEffect(() => {
        setSBTeams(
            teams
                .filter(team => teamIDs.includes(team.id))
                .map(team => {
                    return {
                        ...team,
                        gamesPlayed: scores
                            .filter(score => score.season === season.id)
                            .filter(score => score.team === team.id).length,
                        total: scores
                            .filter(score => score.season === season.id)
                            .filter(score => score.team === team.id)
                            .reduce((prev, curr) => {
                                return prev + curr.score
                            }, 0),
                        avg:
                            scores
                                .filter(score => score.season === season.id)
                                .filter(score => score.team === team.id)
                                .reduce((prev, curr) => {
                                    return prev + curr.score
                                }, 0) /
                            scores
                                .filter(score => score.season === season.id)
                                .filter(score => score.team === team.id).length,
                    }
                })
                .sort((a, b) => b.total - a.total)
        )
    }, [teamIDs, teams])

    const dateChanged = e => {
        const date = Timestamp.fromDate(moment(e.target.value).toDate())
        setFilteredDate(date)
    }

    return season && season.startDate ? (
        <section className="standings-page">
            {!season.active && championship ? (
                <div className="championship-page">
                    <header>
                        <h1>{season.name}</h1>
                        <h2>
                            {moment(season.startDate).format("MMM Do YYYY")} -{" "}
                            {moment(season.endDate).format("MMM Do YYYY")}
                        </h2>
                        <p className="championship-message">
                            {championship.message}
                        </p>
                        <p className="championship-winner">
                            <Link to={`/team/${championship.team}`}>
                                {
                                    teams.find(
                                        team => team.id === championship.team
                                    )?.name
                                }
                            </Link>
                        </p>
                        <p className="championship-team">
                            <img
                                src={championship.photo}
                                alt={`${season.name} Winners`}
                            />
                        </p>
                        <h3 className="final-scores">Final Scores</h3>
                        <p className="final-scoreboard">
                            <img
                                src={championship.scoreboard}
                                alt={`${season.name} Scoreboard`}
                            />
                        </p>
                    </header>
                </div>
            ) : (
                <div className="season-page">
                    <header>
                        <h1>{season.name} Standings</h1>
                        <h2>
                            {moment(season.startDate).format("MMM Do YYYY")} -{" "}
                            {moment(season.endDate).format("MMM Do YYYY")}
                        </h2>
                    </header>
                </div>
            )}

            <div>
                {sbTeams && (
                    <div className="standings-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th
                                        className="sortable"
                                        onClick={() => setSortBy("name")}
                                    >
                                        Team Name{" "}
                                        {sortBy === "name" ? (
                                            <ChevronUpIcon className="icon-small" />
                                        ) : (
                                            ""
                                        )}
                                    </th>
                                    <th
                                        className="sortable"
                                        onClick={() => setSortBy("gamesPlayed")}
                                    >
                                        Games Played{" "}
                                        {sortBy === "gamesPlayed" ? (
                                            <ChevronUpIcon className="icon-small" />
                                        ) : (
                                            ""
                                        )}
                                    </th>
                                    <th
                                        className="sortable"
                                        onClick={() => setSortBy("total")}
                                    >
                                        Total Correct{" "}
                                        {sortBy === "total" ? (
                                            <ChevronUpIcon className="icon-small" />
                                        ) : (
                                            ""
                                        )}
                                    </th>
                                    <th
                                        className="sortable"
                                        onClick={() => setSortBy("avg")}
                                    >
                                        Avg{" "}
                                        {sortBy === "avg" ? (
                                            <ChevronUpIcon className="icon-small" />
                                        ) : (
                                            ""
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sbTeams
                                    .sort((a, b) => {
                                        if (sortBy === "total") {
                                            return b.total - a.total
                                        } else if (sortBy === "avg") {
                                            return b.avg - a.avg
                                        } else if (sortBy === "gamesPlayed") {
                                            return b.gamesPlayed - a.gamesPlayed
                                        } else if (sortBy === "name") {
                                            return a.name.localeCompare(b.name)
                                        }
                                    })
                                    .map(team => (
                                        <tr key={team.id}>
                                            <td>
                                                <Link to={`/team/${team.id}`}>
                                                    {team.name}
                                                </Link>
                                            </td>
                                            <td>{team.gamesPlayed}</td>
                                            <td>{team.total}</td>
                                            <td>{team.avg.toFixed(2)}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <div>
                            <header>
                                <form className="daily-scores">
                                    <h2>Daily Scores</h2>
                                    <label htmlFor="date">Pick a date:</label>
                                    <input
                                        type="date"
                                        id="date"
                                        onChange={dateChanged}
                                        value={moment(
                                            filteredDate.toDate()
                                        ).format("YYYY-MM-DD")}
                                    />
                                </form>
                            </header>

                            {filteredDate &&
                            scores.filter(
                                score =>
                                    score.season === season.id &&
                                    score.date.toDate().toLocaleDateString() ===
                                        filteredDate
                                            .toDate()
                                            .toLocaleDateString()
                            ).length > 0 ? (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>Score</th>
                                            <th>Venue</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {scores
                                            .filter(
                                                score =>
                                                    score.season ===
                                                        season.id &&
                                                    score.date
                                                        .toDate()
                                                        .toLocaleDateString() ===
                                                        filteredDate
                                                            .toDate()
                                                            .toLocaleDateString()
                                            )
                                            .sort((a, b) => b.score - a.score)
                                            .map(score => (
                                                <tr key={score.id}>
                                                    <td>
                                                        <Link
                                                            to={`/team/${
                                                                teams.find(
                                                                    team =>
                                                                        team.id ===
                                                                        score.team
                                                                )?.id
                                                            }`}
                                                        >
                                                            {
                                                                teams.find(
                                                                    team =>
                                                                        team.id ===
                                                                        score.team
                                                                )?.name
                                                            }
                                                        </Link>
                                                    </td>
                                                    <td>{score.score}</td>
                                                    <td>
                                                        {
                                                            venues.find(
                                                                venue =>
                                                                    venue.id ===
                                                                    score.venue
                                                            )?.name
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No scores for this date</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </section>
    ) : (
        <Loading />
    )
}
