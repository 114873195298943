import React from "react"

export default function CategoryList({
    currentRoundHasCategory,
    currentRoundCategory,
    currentRound,
    categories,
    gameRounds,
    setCategory,
    setScoringRound,
    setCurrentRound,
}) {
    return (
        <ul className="live-scoring-list">
            {/* this should only show if the current round selected has a category */}

            {currentRoundHasCategory() && (
                <p>
                    <button onClick={() => setScoringRound(true)}>
                        Score Round {currentRound}
                    </button>
                </p>
            )}
            {currentRoundHasCategory() && (
                <li
                    className="live-scoring-selected-category"
                    onClick={() => setCurrentRound(0)}
                >
                    {currentRoundCategory() || "Choose Category"}
                </li>
            )}
            {categories
                .filter(cat => !gameRounds.find(r => r.category === cat.id))
                .filter(cat => cat.name !== "Pictures")
                .map(category => (
                    <li
                        key={category.id}
                        onClick={() => setCategory(currentRound, category.id)}
                    >
                        {category.name}
                    </li>
                ))}
            {categories
                .filter(cat => gameRounds.find(r => r.category === cat.id))
                .filter(cat => cat.name !== "Pictures")
                .map(category => (
                    <li
                        key={category.id}
                        onClick={() => setCategory(currentRound, category.id)}
                        className="live-scoring-not-available-category"
                    >
                        {category.name} - (Already Used)
                    </li>
                ))}
        </ul>
    )
}
