import React, { useState } from "react"

export default function ScoresWizardStep1({
    seasons,
    setWizardStep,
    setSelectedSeason,
    setSelectedDate,
    setSelectedVenue,
    type = "scores",
}) {
    const [chooseSeason, setChooseSeason] = useState(false)
    const [tmpSeason, setTmpSeason] = useState("")
    return (
        <section>
            <span
                className="close"
                onClick={() => {
                    setWizardStep(0)
                    setSelectedSeason("")
                    setSelectedDate("")
                    setSelectedVenue("")
                }}
            >
                x
            </span>
            <h1>{type === "game" ? "Create Game" : "Enter Scores"}</h1>
            <h2>Step1: Season</h2>
            {type === "game" ? (
                <p>
                    Are you creating a game for the current season or another
                    season?
                </p>
            ) : (
                <p>
                    Are you entering scores for the current season or another
                    season?
                </p>
            )}
            {!chooseSeason ? (
                <>
                    <h2>{seasons.find(season => season.active)?.name}</h2>
                    <p>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setChooseSeason(true)}
                        >
                            Choose Season
                        </button>
                        <button
                            className="btn btn-success"
                            onClick={() => {
                                setWizardStep(2)
                                setSelectedSeason(
                                    seasons.find(season => season.active)?.id
                                )
                            }}
                        >
                            Current Season
                        </button>
                    </p>
                </>
            ) : (
                <div className="grid">
                    {seasons
                        .sort((a, b) => (a.endDate > b.endDate ? -1 : 1))
                        .map(season => (
                            <div
                                className={`grid-item ${
                                    tmpSeason === season.id && "selected"
                                }`}
                                key={season.id}
                                onClick={() => setTmpSeason(season.id)}
                            >
                                {season.name}
                            </div>
                        ))}
                    {tmpSeason && (
                        <button
                            className="btn btn-success"
                            onClick={() => {
                                setWizardStep(2)
                                setSelectedSeason(tmpSeason)
                                setTmpSeason("")
                            }}
                        >
                            Next
                        </button>
                    )}
                </div>
            )}
        </section>
    )
}
