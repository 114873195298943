import React, { useState } from "react"
import moment from "moment"
export default function ScoresWizardStep2({
    setWizardStep,
    setSelectedSeason,
    setSelectedDate,
    setSelectedVenue,
    type = "scores",
}) {
    const [tempDate, setTempDate] = useState(moment().format("YYYY-MM-DD"))
    return (
        <section>
            <span
                className="close"
                onClick={() => {
                    setWizardStep(0)
                    setSelectedSeason("")
                    setSelectedDate("")
                    setSelectedVenue("")
                }}
            >
                x
            </span>
            <h1>{type === "game" ? "Create Game" : "Enter Scores"}</h1>
            <h2>Step 2: Date</h2>
            {type === "game" ? (
                <p>
                    What date are you creating a game for?
                </p>
            ) : (
                <p>
                    What date are you entering scores for?
                </p>
            )}
            <div>
                <input
                    type="date"
                    value={tempDate}
                    onChange={e =>
                        setTempDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                />
                <strong>{moment(tempDate).format("dddd MMM Do YYYY")}</strong>
                <p>
                    <button
                        className="btn btn-success"
                        onClick={() => {
                            setWizardStep(3)
                            setSelectedDate(tempDate)
                        }}
                    >
                        Next
                    </button>
                </p>
            </div>
        </section>
    )
}
