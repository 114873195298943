import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import { collection, doc, addDoc, getDocs, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"

export default function VenuesAdmin() {
    const [venues, setVenues] = useState([])
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    // const [users, setUsers] = useState([])
    const [query, setQuery] = useState("")
    const fetchVenues = async () => {
        await getDocs(collection(db, "venues")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setVenues(newData)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchVenues()
        setLoading(false)
    }, [])

    const updateField = async (id, field, value) => {
        setVenues(
            venues.map(venue => {
                if (venue.id === id) {
                    return {
                        ...venue,
                        [field]: value,
                    }
                }
                return venue
            })
        )
    }

    const saveField = async (id, field, value) => {
        setUpdating(true)
        const venueRef = doc(db, "venues", id)
        await updateDoc(venueRef, {
            [field]: value,
        })
        setUpdating(false)
        setVenues(
            venues.map(venue => {
                if (venue.id === id) {
                    return {
                        ...venue,
                        [field]: value,
                    }
                }
                return venue
            })
        )
    }

    const addVenue = async e => {
        e.preventDefault()
        setUpdating(true)
        const name = e.target.name.value
        const url = e.target.url.value
        const day = e.target.day.value
        const time = e.target.time.value
        const lat = e.target.lat.value
        const lng = e.target.lng.value

        const venueRef = await addDoc(collection(db, "venues"), {
            name,
            url,
            day,
            time,
            lat,
            lng,
        })

        setVenues([
            ...venues,
            {
                id: venueRef.id,
                name,
                url,
                day,
                time,
                lat,
                lng,
            },
        ])
        setUpdating(false)
        e.target.reset()
    }

    const search = e => {
        setQuery(e.target.value)
    }

    return (
        <section className="venues-admin-page">
            <h1>Venues</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            <p>{loading && <span>Venues: Loading...</span>}</p>
            <p>{updating && <span>Venues: Updating...</span>}</p>
            <section>
                <input
                    type="text"
                    placeholder="Search Venues...."
                    onChange={search}
                />
            </section>
            <details>
                <summary>Add Venue</summary>
                <form onSubmit={addVenue}>
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" />
                    <label htmlFor="url">URL</label>
                    <input type="text" id="url" />
                    <label htmlFor="day">Day</label>
                    <select id="day">
                        <option value="">Select a Day</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                    </select>
                    <label htmlFor="time">Start Time</label>
                    <input type="text" id="time" placeholder="7pm" />
                    <label htmlFor="address">Address</label>
                    <input type="text" id="address" />
                    <label htmlFor="city">City</label>
                    <input type="text" id="city" />
                    <label htmlFor="state">State</label>
                    <input type="text" id="state" />
                    <label htmlFor="zip">Zip</label>
                    <input type="text" id="zip" />
                    <label htmlFor="lat">Lat</label>
                    <input type="text" id="lat" />
                    <label htmlFor="lng">Lng</label>
                    <input type="text" id="lng" />
                    <button type="submit">Add venue</button>
                </form>
            </details>
            {venues && (
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>URL</th>
                            <th>Day</th>
                            <th>Time</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip</th>
                            <th>Lat</th>
                            <th>Lng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {venues
                            .filter(venue =>
                                query !== ""
                                    ? venue.name
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                    : true
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(venue => (
                                <tr key={venue.id}>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.name}
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.url}
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "url",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "url",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <select
                                            value={venue.day}
                                            onChange={e =>
                                                saveField(
                                                    venue.id,
                                                    "day",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">
                                                Select a Day
                                            </option>
                                            <option value="Tuesday">
                                                Tuesday
                                            </option>
                                            <option value="Wednesday">
                                                Wednesday
                                            </option>
                                            <option value="Thursday">
                                                Thursday
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.time}
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "time",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "time",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.address}
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "address",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "address",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.city}
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "city",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "city",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.state}
                                            placeholder="MD"
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "state",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "state",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.zip}
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "zip",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "zip",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.lat}
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "lat",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "lat",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={venue.lng}
                                            onChange={e =>
                                                updateField(
                                                    venue.id,
                                                    "lng",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    venue.id,
                                                    "lng",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </section>
    )
}
