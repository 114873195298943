import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import moment from "moment"

import { TbHelpHexagonFilled } from "react-icons/tb"

import dataService from "../../data/dataService"

import {
    collection,
    doc,
    getDocs,
    updateDoc,
    Timestamp,
    limit,
    query,
    orderBy,
    where,
    addDoc,
} from "firebase/firestore"
import { auth, db } from "../../firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import ScoresWizardStep1 from "./Scores/Step1"
import ScoresWizardStep2 from "./Scores/Step2"
import ScoresWizardStep3 from "./Scores/Step3"

import "./ScoresWizard.css"
import "./HostDashboard.css"
import ScoresWizardStep4 from "./Scores/Step4"
import ScoresWizardStep5 from "./Scores/Step5"

import LiveScoring from "../../components/LiveScoring/LiveScoring"
import DayButton from "./DayButton"

// this component is not just used for the Scores Admin
// but also for the Host Dashboard

// const gameOptions.numberOfRoundsPerGame = 7
// const questionsPerRound = [8, 8, 8, 8, 8, 8, 8]

const gameOptions = {
    numberOfRoundsPerGame: 7,
    questionsPerRound: [8, 8, 8, 8, 8, 8, 8],
    legacyNumberOfRoundsPerGame: 7,
    legacyQuestionsPerRound: [6, 6, 10, 6, 6, 10, 6],
}

export default function HostDashboard({ admin }) {
    const [savingGame, setSavingGame] = useState(false)
    const [scores, setScores] = useState([])
    const [seasons, setSeasons] = useState([])
    const [teams, setTeams] = useState([])
    const [venues, setVenues] = useState([])
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [user] = useAuthState(auth)
    const [userData, setUserData] = useState({})
    const [searchString, setSearchString] = useState("")
    const [filteredDate, setFilteredDate] = useState("")
    const [wizardStep, setWizardStep] = useState(0)
    const [selectedSeason, setSelectedSeason] = useState("")
    const [selectedDate, setSelectedDate] = useState("")
    const [selectedVenue, setSelectedVenue] = useState("")
    const [games, setGames] = useState([])
    const [showGame, setShowGame] = useState(false)
    const [gameVenue, setGameVenue] = useState("")
    const [categories, setCategories] = useState([])
    const [currentRound, setCurrentRound] = useState(0)
    const [showHelp, setShowHelp] = useState(false)
    const [rounds, setRounds] = useState([
        {
            round: 1,
            category: "",
        },
        {
            round: 2,
            category: "",
        },
        {
            round: 3,
            category: "",
        },
        {
            round: 4,
            category: "",
        },
        {
            round: 5,
            category: "",
        },
        {
            round: 6,
            category: "",
        },
        {
            round: 7,
            category: "",
        },
        {
            round: 8,
            category: "",
        },
    ])

    const showLiveScoring = date => {
        const game = games.find(
            game => moment(game.date).format("YYYY-MM-DD") === date
        )

        // if (!game && moment(date) < moment().startOf("day")) {
        //     return
        // }

        if (game) {
            setShowGame(game)
            setGameVenue(venues.find(venue => venue.id === game.venue))
        } else {
            showVenueWizard(date)
        }
    }

    const showVenueWizard = date => {
        // show the venue wizard
        setSelectedSeason(seasons.find(season => season.active).id)
        setSelectedDate(date)
    }

    const closeLiveScoring = () => {
        setShowGame(false)
        setSelectedDate("")
        setCurrentRound(0)
    }

    useEffect(() => {
        const handleEscapeKey = event => {
            if (event.key === "Escape") {
                closeLiveScoring()
                setShowHelp(false)
            }
        }

        document.addEventListener("keydown", handleEscapeKey)

        return () => {
            document.removeEventListener("keydown", handleEscapeKey)
        }
    }, [])

    const deleteGame = async (id, e) => {
        // show confirmation box
        e.stopPropagation()
        const confirm = window.confirm("Are you sure you want to delete this?")
        if (confirm) {
            setLoading(true)
            await dataService.deleteDoc(id, "games", setGames, games)
            setLoading(false)
        }
    }

    const createGame = async venueID => {
        setSelectedVenue(venueID)
        setSavingGame(true)
        const data = {
            season: selectedSeason,
            venue: venueID,
            date: selectedDate,
            user: user.uid,
            status: "created",
            currentSlide: 1,
        }
        const gameRecord = await addDoc(collection(db, "games"), data)
        // add X records, one for each round into the rounds collection in the database
        // use the numberofRounds
        // for each round, add a record to the rounds collection
        // with the game id, the round number, and the category (blank by default)
        // the category will be updated when the user selects a category
        // the game id will be used to link the rounds to the game
        // the round number will be used to order the rounds
        // the category will be used to store the category for the round
        for (let i = 1; i <= gameOptions.numberOfRoundsPerGame; i++) {
            addDoc(collection(db, "rounds"), {
                game: gameRecord.id,
                round: i,
                category: "",
            })
        }

        setTimeout(() => {
            fetchGames()
            setSavingGame(false)
            setSelectedSeason("")
            setSelectedDate("")
            setSelectedVenue("")
        }, 2500)
    }

    const refreshGames = async () => {
        setLoading(true)
        await fetchGames()
        setLoading(false)
    }

    const fetchGames = async () => {
        await getDocs(collection(db, "games")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setGames(newData.filter(game => game.user === user.uid))
        })
    }

    const fetchCategories = async () => {
        await getDocs(collection(db, "categories")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setCategories(newData)
        })
    }

    const fetchScores = async () => {
        const q = query(
            collection(db, "scores"),
            orderBy("date", "desc"),
            limit(100)
        )
        await getDocs(q).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setScores(
                newData.filter(score =>
                    admin ? true : score.user === user.uid
                )
            )
            setLoading(false)
        })
    }

    const fetchSeasons = async () => {
        await getDocs(collection(db, "seasons")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setSeasons(newData)
            setLoading(false)
        })
    }

    const fetchTeams = async () => {
        await getDocs(collection(db, "teams")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setTeams(newData)
        })
    }

    const fetchVenues = async () => {
        await getDocs(collection(db, "venues")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setVenues(newData)
        })
    }

    const fetchUsers = async () => {
        await getDocs(collection(db, "users")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setUsers(newData)
        })
    }

    useEffect(() => {
        if (user) {
            setLoading(true)
            fetchGames()
            fetchScores()
            fetchSeasons()
            fetchTeams()
            fetchVenues()
            fetchUsers()
            fetchCategories()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const updateScore = async (id, scoreValue) => {
        setScores(
            scores.map(score => {
                if (score.id === id) {
                    return {
                        ...score,
                        score: Number(scoreValue),
                    }
                }
                return score
            })
        )
    }

    const saveScoreValue = async (id, scoreValue) => {
        setUpdating(true)
        const scoreRef = doc(db, "scores", id)
        await updateDoc(scoreRef, {
            score: Number(scoreValue),
        })
        setUpdating(false)
    }

    const updateDate = async (id, dateValue) => {
        setScores(
            scores.map(score => {
                if (score.id === id) {
                    return {
                        ...score,
                        date: Timestamp.fromDate(moment(dateValue).toDate()),
                    }
                }
                return score
            })
        )
    }

    const saveDateValue = async (id, dateValue) => {
        setUpdating(true)
        const scoreRef = doc(db, "scores", id)
        await updateDoc(scoreRef, {
            date: Timestamp.fromDate(moment(dateValue).toDate()),
        })
        setUpdating(false)
    }

    const search = e => {
        const keyword = e.target.value
        setSearchString(keyword)
    }

    const performSearch = e => {
        setLoading(true)
        e.preventDefault()
        const matchingTeams = teams
            .filter(team =>
                team.name.toLowerCase().includes(searchString.toLowerCase())
            )
            .map(team => team.id)
        let q = false
        if (searchString !== "") {
            q = query(
                collection(db, "scores"),
                where("team", "in", matchingTeams)
            )
        }
        if (filteredDate !== "") {
            q = query(
                collection(db, "scores"),
                where(
                    "date",
                    "==",
                    Timestamp.fromDate(moment(filteredDate).toDate())
                )
            )
        }
        if (q) {
            getDocs(q).then(querySnapshot => {
                const newData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setScores(newData)
                setLoading(false)
            })
        }
    }

    const deleteScore = async id => {
        // show confirmation box

        const confirm = window.confirm("Are you sure you want to delete this?")
        if (confirm) {
            setLoading(true)
            await dataService.deleteDoc(id, "scores", setScores, scores)
            setLoading(false)
        }
    }

    useEffect(() => {
        dataService.getData("seasons", () => {}).then(setSeasons)
        getUserData()
    }, [user])

    const getUserData = async () => {
        if (user) {
            const q = query(
                collection(db, "users"),
                where("uid", "==", user.uid)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                setUserData(doc.data())
            })
        }
    }

    return (
        <section className="scores-enter-page host-dashboard">
            <button className="help-button" onClick={() => setShowHelp(true)}>
                <TbHelpHexagonFilled />
            </button>
            <h1>{admin ? "Scores Admin" : "Host Dashboard"}</h1>
            {admin && (
                <p>
                    <Link to="/admin">Admin</Link>
                </p>
            )}
            <hr />
            <p>{loading && <span>Scores: Loading...</span>}</p>
            <p>{updating && <span>Scores: Updating...</span>}</p>
            {savingGame && (
                <p className="savingGame">
                    <span>Creating Game...</span>
                </p>
            )}
            <section className="day-buttons-wrapper">
                {/* I would like to show 3 buttons, one for Tuesday, one for Wednesday, one for Thursday */}
                <section className="day-buttons">
                    <DayButton
                        date={moment().startOf("week").add(2, "days")}
                        label="Tuesday"
                        showLiveScoring={showLiveScoring}
                        games={games}
                        venues={venues}
                    />
                    <DayButton
                        date={moment().startOf("week").add(3, "days")}
                        label="Wednesday"
                        showLiveScoring={showLiveScoring}
                        games={games}
                        venues={venues}
                    />
                    <DayButton
                        date={moment().startOf("week").add(4, "days")}
                        label="Thursday"
                        showLiveScoring={showLiveScoring}
                        games={games}
                        venues={venues}
                    />
                </section>
                <hr />
            </section>

            {!admin && games && games.length > 0 && (
                <section className="old-games">
                    <h2>Old Games</h2>
                    <ul className="game-list">
                        {games
                            .sort((a, b) => {
                                return a.date < b.date ? 1 : -1
                            })
                            .filter(
                                game =>
                                    moment(game.date) < moment().startOf("week")
                            )
                            .map(game => (
                                <li key={game.id}>
                                    {moment(game.date).format(
                                        "dddd MM/DD/YYYY"
                                    )}{" "}
                                    -{" "}
                                    {
                                        venues.find(
                                            venue => venue.id === game.venue
                                        )?.name
                                    }{" "}
                                    {/* <Link to={`/game/${game.id}`}> */}
                                    <button
                                        onClick={() => {
                                            showLiveScoring(
                                                moment(game.date).format(
                                                    "YYYY-MM-DD"
                                                )
                                            )
                                        }}
                                    >
                                        View Game
                                    </button>
                                    {/* </Link> */}
                                </li>
                            ))}
                    </ul>
                </section>
            )}

            {(showGame || selectedDate) && wizardStep === 0 && (
                <LiveScoring
                    game={showGame}
                    close={closeLiveScoring}
                    selectedDate={selectedDate}
                    createGame={createGame}
                    refreshGames={refreshGames}
                    numberOfRoundsPerGame={gameOptions.numberOfRoundsPerGame}
                    questionsPerRound={gameOptions.questionsPerRound}
                    // currentRound={currentRound}
                    // setCurrentRound={setCurrentRound}
                    // rounds={rounds}
                    // categories={categories}
                    // setCategory={setCategory}
                />
            )}

            {wizardStep > 0 && (
                <section className="wizard-overlay">
                    <section className="wizard">
                        {wizardStep === 1 && (
                            <ScoresWizardStep1
                                seasons={seasons}
                                venues={venues}
                                teams={teams}
                                setUpdating={setUpdating}
                                setScores={setScores}
                                scores={scores}
                                fetchTeams={fetchTeams}
                                setWizardStep={setWizardStep}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                        {wizardStep === 2 && (
                            <ScoresWizardStep2
                                setWizardStep={setWizardStep}
                                setSelectedDate={setSelectedDate}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                        {wizardStep === 3 && (
                            <ScoresWizardStep3
                                venues={venues}
                                setWizardStep={setWizardStep}
                                setSelectedVenue={setSelectedVenue}
                                selectedDate={selectedDate}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                            />
                        )}

                        {wizardStep === 4 && (
                            <ScoresWizardStep4
                                teams={teams}
                                setWizardStep={setWizardStep}
                                venue={venues.find(
                                    venue => venue.id === selectedVenue
                                )}
                                date={selectedDate}
                                season={seasons.find(
                                    season => season.id === selectedSeason
                                )}
                                fetchTeams={fetchTeams}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                            />
                        )}
                        {wizardStep === 5 && (
                            <ScoresWizardStep5
                                setWizardStep={setWizardStep}
                                venue={venues.find(
                                    venue => venue.id === selectedVenue
                                )}
                                date={selectedDate}
                                season={seasons.find(
                                    season => season.id === selectedSeason
                                )}
                                setSelectedSeason={setSelectedSeason}
                                setSelectedDate={setSelectedDate}
                                setSelectedVenue={setSelectedVenue}
                                fetchData={fetchScores}
                            />
                        )}
                    </section>
                </section>
            )}
            {showHelp && (
                <section className="help-overlay">
                    <section className="help">
                        <span
                            className="close"
                            onClick={() => setShowHelp(false)}
                        >
                            x
                        </span>
                        <h1>Host Training</h1>
                        <div className="host-training-video">
                            <iframe
                                src="https://www.loom.com/embed/6f949b7a9b5c4673b3ed5a4d0635a1db?sid=94cd490f-9440-4079-bf18-bdc4d84f2ff9"
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowFullScreen
                                className="host-training-video-iframe"
                                title="host training"
                            ></iframe>
                            <div className="info">
                                <h2>Host Dashboard 2.0</h2>
                                <p>
                                    In this video, we will guide you through the
                                    host dashboard and show how to navigate it
                                    like a pro. You'll learn how to select game
                                    days, choose venues, create games, enter
                                    teams, and even customize team names. We'll
                                    also explain how to score rounds, change
                                    categories, and finalize scores. Plus, I'll
                                    give you a sneak peek into the future
                                    features we have in store.{" "}
                                    <span className="time">8 minutes</span>.
                                </p>
                                <a
                                    href="https://www.loom.com/share/6f949b7a9b5c4673b3ed5a4d0635a1db"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Video Link
                                </a>
                            </div>
                        </div>
                    </section>
                </section>
            )}
        </section>
    )
}
