import React from "react"
import moment from "moment"

import "./DayButton.css"

export default function DayButton({
    date,
    label,
    showLiveScoring,
    games,
    venues,
}) {
    const calculateDayButtonClass = date => {
        const game = games.find(
            game => moment(game.date).format("YYYY-MM-DD") === date
        )
        // if (!game && moment(date) < moment().startOf("day")) {
        //     return "day-button past"
        // } else if (!game) {
        if (!game) {
            return "day-button"
        } else if (game.status === "finalized") {
            return "day-button finalized"
        } else {
            return "day-button active"
        }
    }

    const processGameButtonHeadline = date => {
        if (games.length === 0 || venues.length === 0) {
            return "Select a Venue"
        }

        const game = games.find(
            game => moment(game.date).format("lll") === date
        )

        // if (!game && moment(date) < moment().startOf("day")) {
        //     return "Missed"
        // } else if (!game) {
        if (!game) {
            return "Create Game / Select a Venue"
        } else {
            const venue = venues.find(venue => venue.id === game.venue)
            return venue?.name
        }
    }
    return (
        <div
            className={calculateDayButtonClass(
                moment(date).format("YYYY-MM-DD")
            )}
            onClick={() => {
                showLiveScoring(moment(date).format("YYYY-MM-DD"))
            }}
        >
            <h2>{processGameButtonHeadline(moment(date).format("lll"))}</h2>
            {games.find(
                game =>
                    moment(game.date).format("YYYY-MM-DD") ===
                    moment(date).format("YYYY-MM-DD")
            )?.status === "finalized" && <h3>Finalized</h3>}
            <div>
                <p>{moment(date).format("MMMM Do, YYYY")}</p>
                <p>{label}</p>
            </div>
        </div>
    )
}
