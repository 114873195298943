import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import {
    collection,
    doc,
    getDocs,
    updateDoc,
} from "firebase/firestore"
import { db } from "../../firebase"
import roles from "../../data/roles"

export default function UserAdmin() {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState("")
    const [updating, setUpdating] = useState(false)
    const fetchPost = async () => {
        await getDocs(collection(db, "users")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }))
            setUsers(newData)
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchPost()
    }, [])

    const updateRole = async (id, role) => {
        setUpdating(true)
        const userRef = doc(db, "users", id)
        await updateDoc(userRef, {
            role: role,
        })
        setUsers(
            users.map(user => {
                if (user.id === id) {
                    return {
                        ...user,
                        role,
                    }
                }
                return user
            })
        )
        setUpdating(false)
    }

    const search = e => {
        setQuery(e.target.value)
    }

    return (
        <section className="users-admin-page">
            <h1>Users</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            <p>{loading && <span>Users: Loading...</span>}</p>
            <p>{updating && <span>Users: Updating...</span>}</p>
            <p>
                <input
                    type="text"
                    onChange={search}
                    placeholder="Search Users..."
                />
            </p>
            {users && (
                <table>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users
                            .filter(user =>
                                query !== ""
                                    ? user.email
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                    : true
                            )
                            .map(user => (
                                <tr key={user.uid}>
                                    <td>{user.email}</td>
                                    <td>
                                        <select
                                            value={user.role}
                                            onChange={e =>
                                                updateRole(
                                                    user.id,
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">
                                                Select Role
                                            </option>
                                            {roles.map(role => (
                                                <option key={role}>
                                                    {role}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </section>
    )
}
