import React, { useEffect, useState } from "react"
import moment from "moment"

import { getDocs, collection, addDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { Timestamp } from "firebase/firestore"

import "./LiveScoring.css"
import "./LiveScoringMobile.css"
import dataService from "../../data/dataService"
import VenueList from "./VenueList"
import RoundList from "./RoundList"
import TeamsList from "./TeamsList"
import CategoryList from "./CategoryList"
import ScoreboadList from "./ScoreboardList"
import ScoringList from "./ScoringList"
import LiveScoringHeader from "./LiveScoringHeader"
import TeamScoringView from "./TeamScoringView"

export default function LiveScoring({
    game,
    close,
    selectedDate,
    createGame,
    refreshGames,
    numberOfRoundsPerGame,
    questionsPerRound,
}) {
    const [venues, setVenues] = useState([])
    const [gameVenue, setGameVenue] = useState(null)
    const [currentRound, setCurrentRound] = useState(0)
    const [categories, setCategories] = useState([])
    const [gameRounds, setGameRounds] = useState([])
    const [gameTeams, setGameTeams] = useState([])
    const [teams, setTeams] = useState([])
    const [showTeams, setShowTeams] = useState(false)
    const [teamSearch, setTeamSearch] = useState("")
    const [scoringRound, setScoringRound] = useState(false)
    const [gameScores, setGameScores] = useState([])
    const [showScoreboard, setShowScoreboard] = useState(false)
    const [gameScoreboard, setGameScoreboard] = useState([])
    const [currentTeam, setCurrentTeam] = useState(null)

    useEffect(() => {
        getDocs(collection(db, "venues")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setVenues(newData)

            if (game) {
                setGameVenue(newData.find(venue => venue.id === game.venue))
            }
        })
        getDocs(collection(db, "categories")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setCategories(newData)
        })
        getDocs(collection(db, "teams")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }))
            setTeams(newData)
        })
        if (game) {
            dataService.getGameRounds(game.id).then(data => {
                setGameRounds(data.sort((a, b) => a.round - b.round))
            })
            dataService.getGameTeams(game.id).then(data => {
                setGameTeams(data)
            })
            dataService.getGameScores(game.id).then(data => {
                setGameScores(data)
            })
        }
    }, [game])

    useEffect(() => {
        if (
            game.status === "finalized" &&
            gameRounds.length > 0 &&
            gameScores.length > 0 &&
            gameTeams.length > 0
        ) {
            prepareScoreboard()
        }
    }, [game, gameRounds, gameScores, gameTeams])

    const handleGoBack = () => {
        if (showTeams) {
            setShowTeams(false)
        } else if (showScoreboard) {
            setShowScoreboard(false)
            if (game.status === "finalized") {
                close()
            }
        } else if (currentTeam) {
            setCurrentTeam(null)
        } else if (scoringRound) {
            setScoringRound(false)
            setCurrentRound(0)
        } else if (currentRound > 0) {
            setCurrentRound(0)
        } else {
            close()
        }
    }

    const prepareScoreboard = () => {
        // update gameTeams, gameScores

        dataService.getGameTeams(game.id).then(data => {
            setGameTeams(data)
        })
        dataService.getGameScores(game.id).then(data => {
            setGameScores(data)
        })

        setTimeout(() => {
            const gameScoreboard = gameTeams.map(gameTeam => {
                const team = teams.find(team => team.id === gameTeam.team)
                const teamScores = gameScores.filter(
                    gs => gs.team === gameTeam.team
                )
                const teamScore = teamScores.reduce((acc, gs) => {
                    const round = gameRounds.find(r => r.id === gs.round)
                    const roundScore = gs.questions.reduce((acc, q) => {
                        return q.correct ? acc + 1 : acc
                    }, 0)
                    return acc + roundScore
                }, 0)
                return {
                    team: team.name,
                    score: teamScore,
                    displayName: gameTeam.displayName,
                }
            })
            setGameScoreboard(gameScoreboard)
            setShowScoreboard(true)
            setShowTeams(false)
        }, 100)
    }

    const setCategory = (round, category) => {
        const gameRound = gameRounds.find(r => r.round === round)
        dataService.updateField(
            gameRound.id,
            "category",
            category,
            setGameRounds,
            gameRounds
        )
        dataService.saveField(
            gameRound.id,
            "category",
            category,
            "rounds",
            () => {}
        )
        setCurrentRound(0)
    }

    const addTeamToGame = (team, game) => {
        addDoc(collection(db, "gameteams"), {
            team,
            game,
        }).then(() => {
            dataService.getGameTeams(game).then(data => {
                setGameTeams(data)
                setTeamSearch("")
            })
        })
        // insert a gamescore for this team for each round based on numberOfRoundsPerGame
        gameRounds.forEach(round => {
            addDoc(collection(db, "gamescores"), {
                team,
                round: round.id,
                game,
                questions: Array.from(
                    { length: questionsPerRound[round.round - 1] },
                    (_, i) => ({ question: i + 1, correct: false })
                ),
                touched: !!round.category,
            })
        })

        dataService.getGameScores(game).then(data => {
            setGameScores(data)
        })
    }

    const removeTeamFromGame = team => {
        const gameTeam = gameTeams.find(gt => gt.team === team)
        dataService.deleteDoc(gameTeam.id, "gameteams", setGameTeams, gameTeams)
        // remove all gamescores for this team
        const teamScores = gameScores.filter(gs => gs.team === team)
        teamScores.forEach(score => {
            dataService.deleteDoc(
                score.id,
                "gamescores",
                setGameScores,
                gameScores
            )
        })
        setGameScores(gameScores.filter(gs => gs.team !== team))
    }

    const processCurrentRound = round => {
        setCurrentRound(round)
        // if this round already has a category assigned to it, then we can go straight to scoring
        const gameRound = gameRounds.find(r => r.round === round)
        if (gameRound.category !== "") {
            setScoringRound(true)
        }
    }

    const setScoreTouched = score => {
        dataService.saveField(score.id, "touched", true, "gamescores", () => {})
        setGameScores(
            gameScores.map(gs =>
                gs.id === score.id ? { ...score, touched: true } : gs
            )
        )
    }

    const setScoring = (team, round, question, correct) => {
        const gameRound = gameRounds.find(r => r.round === round)
        const gameScore = gameScores.find(
            gs => gs.team === team && gs.round === gameRound.id
        )
        if (gameScore) {
            gameScore.questions = gameScore.questions.some(
                q => q.question === question
            )
                ? gameScore.questions.map(q =>
                      q.question === question ? { question, correct } : q
                  )
                : [...gameScore.questions, { question, correct }]
            setGameScores(
                gameScores.map(gs => (gs.id === gameScore.id ? gameScore : gs))
            )
            dataService.saveField(
                gameScore.id,
                "questions",
                gameScore.questions,
                "gamescores",
                () => {}
            )
        } else {
            addDoc(collection(db, "gamescores"), {
                team,
                round: gameRound.id,
                game: game.id,
                questions: [{ question, correct }],
                touched: true,
            }).then(() => {
                dataService.getGameScores(game.id).then(data => {
                    setGameScores(data)
                })
            })
        }
    }

    const currentRoundHasCategory = () => {
        return gameRounds.find(r => r.round === currentRound).category !== ""
    }

    const currentRoundCategory = () => {
        return categories.find(
            cat =>
                cat.id ===
                gameRounds.find(r => r.round === currentRound)?.category
        )?.name
    }

    const calculateThirdLine = () => {
        if (game.status === "finalized") {
            return "Finalized"
        } else if (currentRound > 0 && !scoringRound) {
            return `Round ${currentRound} - ${
                currentRoundCategory() || "Choose Category"
            }`
        } else if (scoringRound) {
            // when clicking on the scoring-category span, this should show the category list again

            return (
                <span>
                    Round {currentRound} -{" "}
                    <span
                        className="scoring-category"
                        onClick={() => {
                            setScoringRound(false)
                            setCurrentRound(currentRound)
                        }}
                    >
                        {currentRoundCategory() || "Choose Category"}
                    </span>{" "}
                    - Scoring
                </span>
            )
        } else if (showScoreboard) {
            return "Scoreboard"
        } else if (showTeams) {
            return "Teams"
            // else if we are choosing a venue
        } else if (selectedDate) {
            return "Choose Venue"
        } else {
            return "Choose Round"
        }
    }

    const finalizeScores = () => {
        /*
            score object is one per day per team per venue per season
            {
                date: current date,
                team: team id,
                venue: venue id,
                season: season id,
                score: total score
                user: user id
            }
        */
        //    first let's do a confirmation
        if (window.confirm("Are you sure you want to finalize the scores?")) {
            gameTeams.forEach(gameTeam => {
                const team = teams.find(team => team.id === gameTeam.team)
                const teamScores = gameScores.filter(
                    gs => gs.team === gameTeam.team
                )
                const teamScore = teamScores.reduce((acc, gs) => {
                    const round = gameRounds.find(r => r.id === gs.round)
                    const roundScore = gs.questions.reduce((acc, q) => {
                        return q.correct ? acc + 1 : acc
                    }, 0)
                    return acc + roundScore
                }, 0)

                addDoc(collection(db, "scores"), {
                    date: Timestamp.fromDate(moment(game.date).toDate()),
                    team: team.id,
                    venue: game.venue,
                    season: game.season,
                    score: teamScore,
                    user: game.user,
                })
            })
            //    then we need to update the game to be finalized
            dataService.saveField(
                game.id,
                "status",
                "finalized",
                "games",
                () => {}
            )
            refreshGames()
            // close
            close()
        }
    }

    return (
        <section className="live-scoring">
            <LiveScoringHeader
                handleGoBack={handleGoBack}
                game={game}
                gameVenue={gameVenue}
                selectedDate={selectedDate}
                gameTeams={gameTeams}
                showTeams={showTeams}
                setShowTeams={setShowTeams}
                setCurrentRound={setCurrentRound}
                setShowScoreboard={setShowScoreboard}
                calculateThirdLine={calculateThirdLine}
                setCurrentTeam={setCurrentTeam}
            />
            <main>
                {selectedDate && (
                    <VenueList
                        venues={venues}
                        createGame={createGame}
                        selectedDate={selectedDate}
                    />
                )}

                {game &&
                    !selectedDate &&
                    currentRound === 0 &&
                    !showScoreboard &&
                    !showTeams &&
                    game.status !== "finalized" && (
                        <RoundList
                            gameRounds={gameRounds}
                            gameScores={gameScores}
                            gameTeams={gameTeams}
                            categories={categories}
                            processCurrentRound={processCurrentRound}
                            numberOfRoundsPerGame={numberOfRoundsPerGame}
                            questionsPerRound={questionsPerRound}
                        />
                    )}

                {game &&
                    !selectedDate &&
                    currentRound === 0 &&
                    !showScoreboard &&
                    showTeams &&
                    game.status !== "finalized" && (
                        <TeamsList
                            teams={teams}
                            setTeams={setTeams}
                            teamSearch={teamSearch}
                            setTeamSearch={setTeamSearch}
                            game={game}
                            gameTeams={gameTeams}
                            setGameTeams={setGameTeams}
                            addTeamToGame={addTeamToGame}
                            removeTeamFromGame={removeTeamFromGame}
                        />
                    )}

                {game &&
                    !selectedDate &&
                    currentRound > 0 &&
                    !scoringRound &&
                    !showScoreboard &&
                    !showTeams &&
                    game.status !== "finalized" && (
                        <CategoryList
                            currentRoundCategory={currentRoundCategory}
                            currentRoundHasCategory={currentRoundHasCategory}
                            currentRound={currentRound}
                            categories={categories}
                            gameRounds={gameRounds}
                            setCategory={setCategory}
                            setScoringRound={setScoringRound}
                            setCurrentRound={setCurrentRound}
                        />
                    )}

                {game &&
                    !selectedDate &&
                    currentRound > 0 &&
                    scoringRound &&
                    !showScoreboard &&
                    !showTeams &&
                    !currentTeam &&
                    game.status !== "finalized" && (
                        <ScoringList
                            currentRound={currentRound}
                            currentRoundCategory={currentRoundCategory}
                            gameTeams={gameTeams}
                            gameScores={gameScores}
                            gameRounds={gameRounds}
                            setScoring={setScoring}
                            teams={teams}
                            setCurrentRound={setCurrentRound}
                            setScoringRound={setScoringRound}
                            currentTeam={currentTeam}
                            setCurrentTeam={setCurrentTeam}
                            numberOfRoundsPerGame={numberOfRoundsPerGame}
                            questionsPerRound={questionsPerRound}
                        />
                    )}

                {game &&
                    !selectedDate &&
                    currentRound > 0 &&
                    scoringRound &&
                    !showScoreboard &&
                    !showTeams &&
                    currentTeam && (
                        <TeamScoringView
                            game={game}
                            currentTeam={currentTeam}
                            currentRound={currentRound}
                            gameScores={gameScores}
                            gameRounds={gameRounds}
                            setScoring={setScoring}
                            gameTeams={gameTeams}
                            teams={teams}
                            numberOfRoundsPerGame={numberOfRoundsPerGame}
                            questionsPerRound={questionsPerRound}
                            setScoreTouched={setScoreTouched}
                        />
                    )}

                {game && !selectedDate && showScoreboard && (
                    <ScoreboadList
                        gameScoreboard={gameScoreboard}
                        prepareScoreboard={prepareScoreboard}
                    />
                )}
            </main>
            <footer>
                {game.status === "created" && (
                    <button
                        className={`scoreboard-button ${
                            showScoreboard ? "selected" : ""
                        }`}
                        onClick={() =>
                            !showScoreboard
                                ? prepareScoreboard()
                                : setShowScoreboard(false)
                        }
                    >
                        Scoreboard
                    </button>
                )}
                {gameScores.every(gs => gs.touched) &&
                gameScores.length > 0 &&
                game.status === "created" ? (
                    <button className="finalize-score" onClick={finalizeScores}>
                        Finalize Scores
                    </button>
                ) : game.status === "created" ? (
                    <button className="finalize-score-missing" disabled={true}>
                        Finalize Scores (Missing{" "}
                        {gameScores.filter(gs => !!gs.touched === false).length}
                        )
                    </button>
                ) : null}
            </footer>
        </section>
    )
}
