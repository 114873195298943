import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth, logout, db } from "../firebase"
import dataService from "../data/dataService"
import { collection, getDocs, query, where } from "firebase/firestore"

import { Bars3Icon } from "@heroicons/react/24/outline"

export default function Header() {
    const [user] = useAuthState(auth)
    const [userData, setUserData] = useState({})
    const [seasons, setSeasons] = useState([])
    const [mobileMenu, setMobileMenu] = useState(false)

    useEffect(() => {
        dataService.getData("seasons", () => {}).then(setSeasons)
        getUserData()
    }, [user])

    const getUserData = async () => {
        if (user) {
            const q = query(
                collection(db, "users"),
                where("uid", "==", user.uid)
            )
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach(doc => {
                setUserData(doc.data())
            })
        }
    }

    return (
        <header className="main-header">
            <nav>
                <Link to="/">
                    <img alt="Logo" src="/images/logo white no text.png" height="70" />
                </Link>
                <a
                    href="#menu"
                    className="menu"
                    onClick={e => {
                        e.preventDefault()
                        setMobileMenu(!mobileMenu)
                    }}
                >
                    <Bars3Icon height="50" />
                </a>
                <ul className={`mobile-menu-${mobileMenu}`}>
                    <li>
                        <Link
                            to="/schedule"
                            onClick={() => setMobileMenu(false)}
                        >
                            Schedule
                        </Link>
                    </li>
                    <li>
                        <a href="#">Standings</a>
                        <ul>
                            {seasons
                                // .sort((a, b) => (a.active ? -1 : 1))
                                .sort((a, b) =>
                                    a.startDate > b.startDate ? -1 : 1
                                )
                                .map(season => (
                                    <li
                                        key={season.id}
                                        className={`active-season-${season.active}`}
                                    >
                                        <Link
                                            to={`/standings/${season.slug}`}
                                            onClick={() => setMobileMenu(false)}
                                        >
                                            {season.name}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </li>
                    <li>
                        <Link to="/about" onClick={() => setMobileMenu(false)}>
                            About
                        </Link>
                    </li>
                    {user &&
                        (userData.role === "host" ||
                            userData.role === "admin") && (
                            <>
                                <li>
                                    <Link
                                        to="/host-dashboard"
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        Host Dashboard
                                    </Link>
                                </li>
                            </>
                        )}

                    {user && userData.role === "admin" && (
                        <li>
                            <Link
                                to="/admin"
                                onClick={() => setMobileMenu(false)}
                            >
                                Admin
                            </Link>
                        </li>
                    )}

                    <li>
                        {!user ? (
                            <Link
                                to="/login"
                                onClick={() => setMobileMenu(false)}
                            >
                                <button>Login</button>
                            </Link>
                        ) : (
                            <button
                                className="dashboard__btn"
                                onClick={() => {
                                    setMobileMenu(false)
                                    logout()
                                }}
                            >
                                Logout
                            </button>
                        )}
                    </li>
                </ul>
            </nav>
        </header>
    )
}
