import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"

export default function QuestionAdmin() {
    const [categories, setCategories] = useState([])
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState("")
    const [updating, setUpdating] = useState(false)

    const fetchPost = async () => {
        await getDocs(collection(db, "categories")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }))
            setCategories(newData)
            setLoading(false)
        })
        await getDocs(collection(db, "questions")).then(querySnapshot => {
            const newData = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }))
            setQuestions(newData)
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchPost()
    }, [])

    const updateField = async (id, field, value) => {
        setQuestions(
            questions.map(question => {
                if (question.id === id) {
                    return {
                        ...question,
                        [field]: value,
                    }
                }
                return question
            })
        )
    }

    const saveField = async (id, field, value) => {
        setUpdating(true)
        const docRef = doc(db, "questions", id)
        await updateDoc(docRef, {
            [field]: value,
        })
        setQuestions(
            questions.map(question => {
                if (question.id === id) {
                    return {
                        ...question,
                        [field]: value,
                    }
                }
                return question
            })
        )
        setUpdating(false)
    }

    const addQuestion = async e => {
        e.preventDefault()
        setUpdating(true)
        if (
            e.target.title.value === "" ||
            e.target.answer.value === "" ||
            e.target.category.value === ""
        ) {
            alert("Please fill out all fields")
            return
        }
        const docRef = await addDoc(collection(db, "questions"), {
            title: e.target.title.value,
            answer: e.target.answer.value,
            category: e.target.category.value,
        })
        setQuestions([
            ...questions,
            {
                id: docRef.id,
                title: e.target.title.value,
                answer: e.target.answer.value,
                category: e.target.category.value,
            },
        ])
        setUpdating(false)
        e.target.reset()
    }

    const search = e => {
        setQuery(e.target.value)
    }

    return (
        <section className="questions-admin-page">
            <h1>Questions</h1>
            <p>
                <Link to="/admin">Admin</Link>
            </p>
            <hr />
            <p>{loading && <span>Questions: Loading...</span>}</p>
            <p>{updating && <span>Questions: Updating...</span>}</p>
            <section>
                <input
                    type="text"
                    onChange={search}
                    placeholder="Search Questions..."
                />
            </section>
            <details>
                <summary>Add Question</summary>
                <form onSubmit={addQuestion}>
                    <label htmlFor="title">Title</label>
                    <input name="title" type="text" id="title" />
                    <label htmlFor="answer">Answer</label>
                    <input name="answer" type="text" id="answer" />
                    <label htmlFor="category">Category</label>
                    <select name="category" id="category">
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                    <button type="submit">Add</button>
                </form>
            </details>
            {categories && questions && (
                <table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Answer</th>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions
                            .filter(question =>
                                query !== ""
                                    ? question.title
                                          .toLowerCase()
                                          .includes(query.toLowerCase())
                                    : true
                            )
                            .map(question => (
                                <tr key={question.id}>
                                    <td>
                                        <input
                                            type="text"
                                            className="question-title"
                                            value={question.title}
                                            onChange={e =>
                                                updateField(
                                                    question.id,
                                                    "title",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    question.id,
                                                    "title",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={question.answer}
                                            onChange={e =>
                                                updateField(
                                                    question.id,
                                                    "answer",
                                                    e.target.value
                                                )
                                            }
                                            onBlur={e =>
                                                saveField(
                                                    question.id,
                                                    "answer",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <select
                                            value={question.category}
                                            onChange={e =>
                                                saveField(
                                                    question.id,
                                                    "category",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value={""}>
                                                Select a category
                                            </option>
                                            {categories.map(category => (
                                                <option
                                                    key={category.id}
                                                    value={category.id}
                                                >
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            )}
        </section>
    )
}
