import { Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import dataService from "../data/dataService"
import { Timestamp } from "firebase/firestore"
import moment from "moment"
import { ChevronLeftIcon } from "@heroicons/react/24/outline"

import "./Venue.css"

export default function Venue() {
    const [venues, setVenues] = useState([])
    const [venue, setVenue] = useState(null)
    const params = useParams()

    useEffect(() => {
        dataService.getData("venues", () => {}).then(setVenues)
    }, [])

    useEffect(() => {
        const id = params.id
        const venue = venues.find(venue => venue.id === id)
        setVenue(venue)
    }, [params, venues])

    return (
        venue &&
        venue.name && (
            <section className="venue-page">
                <h2>Venue - {venue.name}</h2>
                <p className="back">
                    <Link to="/schedule">
                        <ChevronLeftIcon height="25" />
                        Back to Schedule
                    </Link>
                </p>
                <p>
                    <a href={venue.url}>Website</a>
                </p>
                <p>
                    {venue.day}s - {venue.time}
                </p>
                <p>
                    {venue.address}
                    <br />
                    {venue.city}, {venue.state} {venue.zip}
                </p>
                <p className="icons">
                    <a href={`http://maps.apple.com/?daddr=${venue.address}+${venue.city}+${venue.state}+${venue.zip}`}><img height="50" src="/images/applemaps.png"/></a>
                    <a href={`http://maps.google.com/?daddr=${venue.address}+${venue.city}+${venue.state}+${venue.zip}`}><img height="50" src="/images/googlemaps.png"/></a>
                </p>
            </section>
        )
    )
}
