import moment from 'moment';

export default function Footer() {
    return (
        <footer>
            <p>
                <a href="https://www.facebook.com/SouthernMarylandTrivia">
                    <img src="/images/facebook.png" alt="SMT Facebook" />
                </a>
                <a href="https://www.instagram.com/southernmarylandtrivia/">
                    <img src="/images/instagram.png" alt="SMT Instagram" />
                </a>
            </p>
            <p>
                Email:{" "}
                <a href="mailto:admin@smtrivia.com">
                    admin@smtrivia.com
                </a>
            </p>
            <p>© {moment().format('YYYY')} by Southern Maryland Trivia</p>
        </footer>
    )
}
