import moment from "moment"
import React, { useEffect, useState } from "react"
import { addDoc, collection } from "firebase/firestore"
import { auth, db } from "../../../firebase"
import { Timestamp } from "firebase/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import dataService from "../../../data/dataService"

export default function ScoresWizardStep4({
    teams,
    setWizardStep,
    venue,
    date,
    season,
    fetchTeams,
    setSelectedSeason,
    setSelectedDate,
    setSelectedVenue,
    type = "scores",
    game = false,
    setAddTeams = () => {},
}) {
    const [numTeams, setNumTeams] = useState(1)
    const [teamSearch, setTeamSearch] = useState("")
    const [matchingTeams, setMatchingTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])
    const [scores, setScores] = useState([])
    const [currentTeam, setCurrentTeam] = useState(-1)
    const [createNew, setCreateNew] = useState(false)
    const [createNewIndex, setCreateNewIndex] = useState(-1)
    const [tmpTeam, setTmpTeam] = useState("")
    const [availableTeams, setAvailableTeams] = useState([])
    const [gameTeams, setGameTeams] = useState([])
    const [user] = useAuthState(auth)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if (teamSearch.length > 0) {
            setMatchingTeams(
                availableTeams
                    .filter(team =>
                        team.name
                            .toLowerCase()
                            .includes(teamSearch.toLowerCase())
                    )
                    .filter(team => !selectedTeams.some(t => t.id === team.id))
            )
        } else if (teamSearch.length === 0) {
            setMatchingTeams([])
        }
    }, [teamSearch])

    useEffect(() => {
        setAvailableTeams(teams)
    }, [teams])

    useEffect(() => {
        if (type === "game" && game) {
            dataService.getGameTeams(game.id).then(data => {
                setGameTeams(data)
            })
        }
    }, [game])

    const setTeam = (team, index) => {
        const newTeams = [...selectedTeams]
        newTeams[index] = team
        setSelectedTeams(newTeams)
        if (type === "game") {
            // save the team to the game
            // 1. save to the database a gameteam
            // 2. when page loads if type is game, load from the gameteam database using this game id
            // 3. load those teams into setSelectedTeams so they are already in the list and can be edited
            // 4. we need a way to remove teams from the list and if type is game than we need to delete that gameteam record
            const teamGame = {
                game: game.id,
                team: team.id,
                host: user.uid,
            }
            addDoc(collection(db, "gameteams"), teamGame)
            setGameTeams([...gameTeams, teamGame])
        }
    }

    const createNewTeam = async () => {
        const newTeams = [...selectedTeams]
        const teamRef = await addDoc(collection(db, "teams"), {
            name: tmpTeam,
            captain: "",
        })

        const newTeam = {
            id: teamRef.id,
            name: tmpTeam,
            captain: "",
        }
        newTeams[createNewIndex] = newTeam
        setSelectedTeams(newTeams)
        setAvailableTeams([...availableTeams, newTeam])
        setCreateNew(false)
        setCreateNewIndex(-1)
        setCurrentTeam(-1)
        setTmpTeam("")
        setTeamSearch("")
        fetchTeams()
    }

    const removeGameTeam = async team => {
        // remove from the database
        // deleteDoc where team.game is gameteams.game team.team is gameteams.team
        dataService.deleteGameTeam(team.game, team.team)
        // remove from the list
        setGameTeams([...gameTeams.filter(t => t.team !== team.team)])
    }

    const submitScores = async () => {
        const newScores = []
        let numSaved = 0
        setSaving(true)
        await selectedTeams.forEach(async (team, index) => {
            const score = scores[index]
            if (team && score) {
                const data = {
                    season: season.id,
                    venue: venue.id,
                    date: Timestamp.fromDate(moment(date).toDate()),
                    team: team.id,
                    score: Number(score),
                    user: user.uid,
                }

                const scoreRef = await addDoc(collection(db, "scores"), data)

                newScores.push({ id: scoreRef.id, ...data })
                numSaved++
                if (numSaved === selectedTeams.length) {
                    setSaving(false)
                    setWizardStep(5)
                }
            }
        })
    }

    return (
        <section>
            <span
                className="close"
                onClick={() => {
                    setWizardStep(0)
                    setAddTeams(false)
                    setSelectedSeason("")
                    setSelectedDate("")
                    setSelectedVenue("")
                }}
            >
                x
            </span>
            <h1>{type === "game" ? "Add Teams to Game" : "Enter Scores"}</h1>
            {type === "scores" && <h2>Step 4: Teams & Scores</h2>}
            <p className="text-line">
                <strong>Season:</strong> {season.name}
            </p>
            <p className="text-line">
                <strong>Venue:</strong> {venue.name}
            </p>
            <p className="text-line">
                <strong>Date:</strong>{" "}
                {moment(date).format("dddd, MMMM Do YYYY")}
            </p>
            {type === "scores" && (
                <p>Please enter below the teams and scores</p>
            )}

            <ul className="score-entry-list">
                <p className="text-line">
                    <button
                        className="btn btn-success"
                        onClick={() => setNumTeams(numTeams + 1)}
                    >
                        {type === "game" ? "Add Team" : "Add Score"}
                    </button>
                </p>
                {Array.from(Array(numTeams), (e, i) => {
                    return (
                        <li className="score-entry-item" key={i}>
                            {!selectedTeams[i] || currentTeam === i ? (
                                <input
                                    type="text"
                                    name="team"
                                    id="team"
                                    value={teamSearch}
                                    onChange={e =>
                                        setTeamSearch(e.target.value)
                                    }
                                    onFocus={() => setCurrentTeam(i)}
                                    tabIndex={i + 1}
                                    className="mb-20 mr-10"
                                    placeholder="Team Name"
                                />
                            ) : type === "scores" ? (
                                <span
                                    className="selected-team-name"
                                    onClick={() => setCurrentTeam(i)}
                                >
                                    {selectedTeams[i]?.name}
                                </span>
                            ) : (
                                ""
                            )}
                            {currentTeam === i && teamSearch.length > 0 && (
                                <ul className="score-entry-team-list">
                                    {matchingTeams
                                        .filter(
                                            team =>
                                                !gameTeams.some(
                                                    gt => gt.team === team.id
                                                )
                                        )
                                        .map(team => (
                                            <li
                                                className="score-entry-team-item"
                                                key={team.id}
                                                onClick={() => {
                                                    setTeamSearch("")
                                                    setTeam(team, i)
                                                    setMatchingTeams([])
                                                    setCurrentTeam(-1)
                                                }}
                                            >
                                                {team.name}
                                            </li>
                                        ))}
                                    <li
                                        className="score-entry-team-item create-new-team"
                                        onClick={() => {
                                            setCreateNew(true)
                                            setCreateNewIndex(i)
                                            setMatchingTeams([])
                                            setTmpTeam(teamSearch)
                                            setTeamSearch("")
                                        }}
                                    >
                                        Create New Team....
                                    </li>
                                </ul>
                            )}
                            {type === "scores" && (
                                <input
                                    type="number"
                                    name="score"
                                    id="score"
                                    placeholder="Score"
                                    tabIndex={i + 1}
                                    className="mb-20"
                                    value={scores[i] || ""}
                                    onChange={e => {
                                        const newScores = [...scores]
                                        newScores[i] = e.target.value
                                        setScores(newScores)
                                    }}
                                />
                            )}
                        </li>
                    )
                })}
                {selectedTeams.length > 0 && saving === false ? (
                    <p className="text-line">
                        <button
                            className="btn btn-success"
                            onClick={() => submitScores()}
                        >
                            Submit Scores
                        </button>
                    </p>
                ) : (
                    <h2>{saving === true && "Saving..."}</h2>
                )}
            </ul>
            {gameTeams.length > 0 && (
                <ul className="game-teams-list">
                    <h2>Game Teams</h2>
                    {gameTeams.map(team => (
                        <li key={team.id}>
                            {teams.find(t => t.id === team.team)?.name}
                            <button
                                className="btn btn-danger ml-20"
                                onClick={() => removeGameTeam(team)}
                            >
                                x
                            </button>
                        </li>
                    ))}
                </ul>
            )}
            {createNew && (
                <div className="create-new-team-wrapper">
                    <div className="create-new-team-form">
                        <h2>Add Team</h2>
                        <input
                            type="text"
                            name="team"
                            id="team"
                            placeholder="Team Name"
                            className="mb-20 mr-10"
                            value={tmpTeam}
                            onChange={e => setTmpTeam(e.target.value)}
                            tabIndex={0}
                        />
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                setCreateNew(false)
                                setCreateNewIndex(-1)
                                setTmpTeam("")
                                setTeamSearch("")
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-success"
                            onClick={createNewTeam}
                        >
                            Create
                        </button>
                    </div>
                </div>
            )}
        </section>
    )
}
