import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Timestamp } from "firebase/firestore"

import moment from "moment"

import dataService from "../../data/dataService"
import "./LiveScoringAdmin.css"

export default function GameDetailsAdmin() {
    const [game, setGame] = useState([])
    const [gameTeams, setGameTeams] = useState([])
    const [gameRounds, setGameRounds] = useState([])
    const [gameScores, setGameScores] = useState([])
    const [teams, setTeams] = useState([])
    const [teamEdit, setTeamEdit] = useState(false)
    const [categories, setCategories] = useState([])
    const [prevTotal, setPrevTotal] = useState(0)
    const [currentTotal, setCurrentTotal] = useState(0)

    let { gameid } = useParams()

    const gameOptions = {
        numberOfRoundsPerGame: 7,
        questionsPerRound: [8, 8, 8, 8, 8, 8, 8],
        legacyNumberOfRoundsPerGame: 7,
        legacyQuestionsPerRound: [6, 6, 10, 6, 6, 10, 6],
    }

    useEffect(() => {
        dataService
            .getGameDetails(gameid)
            .then(({ game, gameTeams, gameRounds, gameScores }) => {
                setGame(game)
                setGameTeams(gameTeams)
                setGameRounds(gameRounds)
                setGameScores(gameScores)
            })

        dataService.getData("teams", () => {}).then(teams => setTeams(teams))
        dataService
            .getData("categories", () => {})
            .then(categories => {
                setCategories(categories)
            })
    }, [gameid])

    const onEdit = gameTeam => {
        setTeamEdit({
            gameTeam,
            team: teams.find(team => team.id === gameTeam.team),
        })
        const total = gameScores
            .filter(gs => gs.team === gameTeam.team)
            .reduce(
                (acc, gs) => acc + gs.questions.filter(q => q.correct).length,
                0
            )
        setPrevTotal(total)
        setCurrentTotal(total)
    }

    const updateQuestionScore = (round, question, team) => {
        const gameRound = gameRounds.find(r => r.round === round)
        const gameScore = gameScores.find(
            gs => gs.team === team && gs.round === gameRound.id
        )

        //   what if the question doesn't question in gameScore.questions?

        const updatedGameScore = {
            ...gameScore,
            questions: gameScore.questions.map(q => {
                if (q.question === question) {
                    return {
                        ...q,
                        correct: !q.correct,
                    }
                }
                return q
            }),
        }

        // If the question doesn't exist in questions, add it
        if (!updatedGameScore.questions.some(q => q.question === question)) {
            updatedGameScore.questions.push({
                question: question,
                correct: true,
            })
        }

        const updatedGameScores = gameScores.map(gs => {
            if (
                gs.team === team &&
                gs.round === gameRounds.find(r => r.round === round).id
            ) {
                return updatedGameScore
            }
            return gs
        })
        setGameScores(updatedGameScores)
        const total = updatedGameScores
            .filter(gs => gs.team === team)
            .reduce(
                (acc, gs) => acc + gs.questions.filter(q => q.correct).length,
                0
            )
        setCurrentTotal(total)

        // now we need to save the updated score
        dataService.saveField(
            updatedGameScore.id,
            "questions",
            updatedGameScore.questions,
            "gamescores",
            () => {}
        )
    }

    const updateFinalScore = () => {
        console.log({ teamEdit, game })
        dataService
            .findScore(
                teamEdit.gameTeam.team,
                Timestamp.fromDate(moment(game.date).toDate()),
                game.venue
            )
            .then(score => {
                if (score) {
                    dataService.saveField(
                        score.id,
                        "score",
                        currentTotal,
                        "scores",
                        () => {}
                    )
                    setCurrentTotal(currentTotal)
                    setPrevTotal(currentTotal)
                }
            })
    }

    return (
        <>
            <section className="live-scores-admin-page">
                <h1>Live Scoring Admin</h1>
                <p>
                    <Link to="/admin/games">Back to Games Admin</Link>
                </p>
                <div>
                    <h2>Game Details</h2>
                    <section className="game-details">
                        <p>{moment(game.date).format("dddd, MMMM Do YYYY")}</p>
                        {/* <p>Host: {game.user}</p>
                        <p>Venue: {game.venue}</p>
                        <p>Teams: {gameTeams.length}</p>
                        <p>Rounds: {gameRounds.length}</p>
                        <p>Scores: {gameScores.length}</p> */}
                    </section>
                </div>

                {gameTeams.length > 0 && (
                    <ul className="live-scores-games-list">
                        <header className="live-scores-games-list-header">
                            <span>Team</span>
                            <span>Display Name</span>
                            <span>Total Score</span>
                            <span>Actions</span>
                        </header>

                        {gameTeams.map(gameTeam => (
                            <li
                                className={`live-scores-game`}
                                key={gameTeam.id}
                            >
                                <p>
                                    {teams &&
                                        teams.find(
                                            team => team.id === gameTeam.team
                                        )?.name}
                                </p>
                                <p>
                                    {gameTeam.displayName
                                        ? gameTeam.displayName
                                        : teams.find(
                                              team => team.id === gameTeam.team
                                          )?.name}
                                </p>
                                <p>
                                    {gameScores
                                        .filter(gs => gs.team === gameTeam.team)
                                        .reduce(
                                            (acc, gs) =>
                                                acc +
                                                gs.questions.filter(
                                                    q => q.correct
                                                ).length,
                                            0
                                        )}
                                </p>
                                <div className="live-scores-admin-actions">
                                    <button
                                        className="live-scores-game-edit"
                                        onClick={() => onEdit(gameTeam)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </section>
            {teamEdit && (
                <div className="live-scores-admin-edit live-scores-admin-edit-team">
                    <a
                        className="close"
                        href="/"
                        onClick={e => {
                            e.preventDefault()
                            setTeamEdit(false)
                            setCurrentTotal(0)
                            setPrevTotal(0)
                        }}
                    >
                        ❌
                    </a>
                    <h2>
                        Edit Team - {teamEdit.team.name} -{" "}
                        {gameScores
                            .filter(gs => gs.team === teamEdit.gameTeam.team)
                            .reduce(
                                (acc, gs) =>
                                    acc +
                                    gs.questions.filter(q => q.correct).length,
                                0
                            )}{" "}
                        points
                    </h2>
                    {currentTotal !== prevTotal &&
                        game.status === "finalized" && (
                            <p>
                                <button
                                    className="update-final-score"
                                    onClick={updateFinalScore}
                                >
                                    Update Final Score?
                                </button>
                            </p>
                        )}
                    <p>
                        <label htmlFor="displayName">Display Name: </label>
                        <input
                            type="text"
                            id="displayName"
                            name="displayName"
                            defaultValue={teamEdit.gameTeam.displayName}
                        />
                    </p>
                    <ul className="live-scores-admin-edit-scores">
                        {Array.from(
                            {
                                length: moment(game.date).isBefore(
                                    moment("2024-06-17")
                                )
                                    ? gameOptions.legacyNumberOfRoundsPerGame
                                    : gameOptions.numberOfRoundsPerGame,
                            },
                            (_, i) => i + 1
                        ).map(round => (
                            <li key={round}>
                                <h3>
                                    Round {round} -{" "}
                                    {
                                        categories.find(
                                            cat =>
                                                cat.id ===
                                                gameRounds.find(
                                                    r => r.round === round
                                                ).category
                                        )?.name
                                    }{" "}
                                </h3>
                                {/* we want a button for each question in the round */}
                                {/* we want to see if game.date is before 06/17/2024 */}
                                {Array.from(
                                    {
                                        length: moment(game.date).isBefore(
                                            moment("2024-06-17")
                                        )
                                            ? gameOptions
                                                  .legacyQuestionsPerRound[
                                                  round - 1
                                              ]
                                            : gameOptions.questionsPerRound[
                                                  round - 1
                                              ],
                                    },
                                    (_, i) => i + 1
                                ).map(question => (
                                    <button
                                        onClick={() => {
                                            updateQuestionScore(
                                                round,
                                                question,
                                                teamEdit.gameTeam.team
                                            )
                                        }}
                                        key={question}
                                        className={`scores-admin-score-button ${
                                            gameScores.find(
                                                gs =>
                                                    gs.team ===
                                                        teamEdit.gameTeam
                                                            .team &&
                                                    gs.round ===
                                                        gameRounds.find(
                                                            r =>
                                                                r.round ===
                                                                round
                                                        ).id &&
                                                    gs.questions.find(
                                                        q =>
                                                            q.question ===
                                                            question
                                                    )?.correct
                                            )
                                                ? "correct"
                                                : gameScores.find(
                                                      gs =>
                                                          gs.team ===
                                                              teamEdit.gameTeam
                                                                  .team &&
                                                          gs.round ===
                                                              gameRounds.find(
                                                                  r =>
                                                                      r.round ===
                                                                      round
                                                              ).id &&
                                                          gs.questions.find(
                                                              q =>
                                                                  q.question ===
                                                                  question
                                                          )?.correct === false
                                                  )
                                                ? "incorrect"
                                                : ""
                                        }`}
                                    >
                                        {question}
                                    </button>
                                ))}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}
